import {AuthContext, serverAddress} from "../App";
import {useContext} from "react";

export default function useCollectConfirm() {
  const { state } = useContext(AuthContext);
  const post = async(requestId) => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        serverAddress + "/nfts/collect/approval",
        { method: "POST", headers: headers, body: JSON.stringify({ requestId: requestId }), }
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return post;
}