import './CommonHomePage.css';
import LibraryIcon from './assets/libraryIcon.svg';
import HomeIcon from './assets/homeIcon.png';
import WalletIcon from './assets/walletIcon.png';
import LogoIcon from './assets/logo.png';
import CalendarIcon from './assets/Calendar.png';
import Card from "./components/card/Card";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import useHomepageCollectionList from "../../utils/useHomepageCollectionList";
import InfiniteScroll from "react-infinite-scroll-component";

export default function CommonHomePage(){
    const [isLogin, setIsLogin] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [list, setList] = useState([]);

    const navigate = useNavigate();
    const getList = useHomepageCollectionList();

    const getHomepageList = async ()=>{
        return await getList(4,1,'status','asc');
    }

    const fetchData = async() => {
        if(pageNum !== 1){
            const data = await getList(4,pageNum,'status', 'asc');
            setList(list.concat(data?.saleCard));
            if (pageNum > Math.floor(data.totalNum/4)) {
                setHasMore(false);
            } else {
                setPageNum(pageNum + 1);
            }
        }
    }

    const toLoginPage = () =>{
        navigate('/login');
    }

    const toEventPage = () =>{
        navigate('/event');
    }

    useEffect(()=>{
       if( localStorage.getItem("userId") && localStorage.getItem("accessToken")){
           setIsLogin(true);
       }
       getHomepageList().then((res)=>{
           setList(res?.saleCard);
           if (pageNum > Math.floor(res.totalNum/4)) {
               setHasMore(false);
           } else {
               setPageNum(pageNum + 1);
           }
       })
    },[])

    return(
        <div className='commonPageContainer'>
            <div className='headerContainer'>
                <img src = {CalendarIcon} alt='' className='leftImg' onClick={toEventPage}/>
                <img src = {LogoIcon} alt='' className='rightImg'/>
            </div>

                <InfiniteScroll dataLength={list?.length} next={fetchData} hasMore={hasMore}
                                loader={<p style={{ textAlign: 'center',color: "white" }}><b>加载中...</b></p>}>
                    <div className='content'>
                    {
                        list?.map((item,index)=>{
                            return(
                                <Card nft={item} key={index}/>
                            )
                        })
                    }
                    </div>
                </InfiniteScroll>

            {
                isLogin?
                    null:
                    <div className='guideFooter'>
                    立即加入「星际岛」
                    <div className='button' onClick={toLoginPage}>
                        <div className='buttonText'>
                            去注册
                        </div>
                    </div>
                </div>
            }
            <div className="footerContainer">
                <div className='footerImg'>
                    <div>
                        <img src = {HomeIcon} alt='' className='leftImg'/>
                    </div>
                    <div>
                        <img src = {LibraryIcon} alt='' className='middleImg' onClick={()=>{navigate('/library')}}/>
                    </div>
                    <div>
                        <img src = {WalletIcon} alt='' className='rightImg' onClick={()=>{navigate('/homepage')}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}