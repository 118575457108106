import DefaultNftAvatar from "../../assets/defaultNftAvatar.png";
import DefaultNonNftAvatar from '../../assets/defaultNonNftAvatar.png';
import CameraIcon from '../../assets/cameraIcon.png';
import NftAvatarIcon from '../../assets/nftAvatarIcon.png';
import './HexagonAvatar.css';
import Frame from './assets/frame.png';

export default function HexagonAvatar(props){
    const { src, nonBorder, scale, onClick, hexagon, icon } = props;

    const style = {
        transform: `scale(${scale},${scale})`
    }

    const circleStyle ={
        border: nonBorder? 'none':'6px solid #282C49', transform: `scale(${scale},${scale})`
    }

    return(
        hexagon?
        <div className='hex' style={style} onClick={onClick}>
            <div className="avatar">
                <img src={Frame} style={{ width: '131px', height: '118px'}} alt=""/>
                <div className="container">
                    <img src={src? src : DefaultNftAvatar} alt=""/>
                </div>
            </div>
            {
                icon?<img src={NftAvatarIcon} alt='' className='nftAvatarIcon'/>:  null
            }


            <svg height="0" width="0">
                <defs>
                    <clipPath id="svgPath">
                        <path fill="#red" stroke="#red" strokeWidth="10"
                              strokeMiterlimit="10"
                              d="M93.0139721,22.6 L56.6866267,1.8 C54.5908184,0.6 52.1956088,0 49.9001996,0 C47.6047904,0 45.2095808,0.6 43.1137725,1.8 L6.78642715,22.6 C2.59481038,25 0,29.4 0,34.2 L0,75.8 C0,80.6 2.59481038,85 6.78642715,87.4 L43.2135729,108.2 C45.3093812,109.4 47.6047904,110 50,110 C52.2954092,110 54.6906188,109.4 56.7864271,108.2 L93.2135729,87.4 C97.4051896,85 100,80.6 100,75.8 L100,34.2 C99.8003992,29.4 97.2055888,25 93.0139721,22.6 L93.0139721,22.6 Z"/>
                    </clipPath>
                </defs>
            </svg>
        </div>:
            <div className='circleAvatarContainer' style={circleStyle}>
                <img src={src? src: DefaultNonNftAvatar} className='imgAvatar' alt=""/>
                {
                    icon?<img src = {CameraIcon} className='circleAvatarIcon' alt=""/>:  null
                }
            </div>
        )

}