import {confluxAddress} from "../App";

function validatePhone(phone){
  return /^1\d{10}$/.test(phone);
}

function validateCode(code){
  return /\d{6}$/.test(code);
}

function validateAddress(address){
  switch (confluxAddress) {
    case "TESTNET":
      return /cfxtest:.{42}$/.test(address);
    case "TETHYS":
      return /cfx:.{42}$/.test(address);
    default:
      return '未知地址';
  }
}

export { validatePhone, validateCode, validateAddress };