import React from 'react';
import {Dialog} from '@mui/material';
import './cropperDialog.css';
import backIcon from '../../assets/back_white.svg';

function CropperDialog(props) {
    const { uploadImage, setOpen, open } = props;

    const handleClose = () => {
        setOpen(false);
        document.getElementById("uploadImg").value = '';
    }

    return (
        <Dialog fullScreen open={open} onClose={handleClose} PaperProps={{
            style: {backgroundColor: "black"}
        }}>
            <div className="cropper">
                <div className="cropper-title relative flex justify-center mt-5 text-white">
                    <img className="absolute left-4" width="26" height="26" src={backIcon} onClick={handleClose} alt=''/>
                    调整尺寸
                </div>
              <div style={{width: '305px', height: '305px', margin: 'auto', border: 'none', background: 'transparent'}}>
                    <img alt="" id="cropImg" style={{visibility: 'hidden'}}/>
              </div>
                <div>
                    <button className='confirm' onClick={uploadImage}>确认修改</button>
                </div>
            </div>
        </Dialog>
    )
}

export default CropperDialog;