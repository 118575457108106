import {portalAddress} from "../App";
import {Toast} from "antd-mobile";

export default function userUpdateAvatar() {
    let headers = new Headers();
    headers.append("accessToken", localStorage.getItem("accessToken"));

    const updateAvatar = async(file, selectItem) => {
        if(selectItem){
            headers.append('X-Nft', selectItem?.id);
        }

        try {
            const response = await fetch(
                portalAddress + "/user/avatar" ,
                {
                    method: "POST",
                    headers: headers,
                    body: file
                });
            const json = await response.json();
            if (json.returnCode === "20000") {
                return json.data;
            } else {
                Toast.show({
                    content:json.msg,
                    position:"bottom"
                })
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return false;
        }
    }

    return updateAvatar;
}