import {AuthContext} from "../../App";
import {smallAndProgressiveImage} from "../../config";
import {useContext, useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import useProfile from "../../utils/useProfile";
import useNFTList from "../../utils/useNFTList";
import "./Home.css";
import LibraryIcon from './assets/libraryIcon.svg';

import menuButton from "../../assets/menu.svg";
import galaxyLogo from "../../assets/galaxy-logo.svg";
import noContentImg from "../../assets/no-content.png";
import claimIcon from "../../assets/claim-icon.svg";
import calendarIcon from "../../assets/calendar-icon.svg";
import importIcon from "../../assets/import-icon.svg";
import closeIcon from "../../assets/close-icon.svg";
import SideBar from "../../components/SideBar";
import InfiniteScroll from "react-infinite-scroll-component";
import Claim from "../../components/Claim/Claim";
import AuthDialog from "./components/AuthDialog";
import ImportDialog from "./components/ImportDialog";
import {useGetAuthentication} from "../../utils/useAuthentication";

import blackBiscuit from "../../assets/biscuit.png";
import whiteBiscuit from "../../assets/biscuit-white.png";
import {Dialog} from "@mui/material";
import HexagonAvatar from "../../components/hexagonAvatar/HexagonAvatar";
import HomeIcon from "./assets/home.png";
import WalletIcon from "./assets/wallet.png";
import ProgressiveImage from "../../components/progressiveImage/ProgressiveImage";

export default function Home() {
  const { state } = useContext(AuthContext);
  const [profile, setProfile] = useState([]);
  const [NFTList, setNFTList] = useState([]);
  const [totalNum, setTotalNum] = useState([]);
  const sideBarRef = useRef();
  // const [priceHidden, setPriceHidden] = useState(false);
  const [, setAuthenticated] = useState(false);

  const navigate = useNavigate();
  const getProfile = useProfile();
  const getNFTList = useNFTList();

  const [claimOpen, setClaimOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const getAuth = useGetAuthentication();

  const handleClaim = () => {
    // if (authenticated === 1) {
      setClaimOpen(true);
    // } else {
    //   setAuthOpen(true);
    // }
  }

  const handleClaimClose = () => {
    setClaimOpen(false);
    refreshData();
  }
  
  const handleImport = () => {
    setImportOpen(true);
  }

  const handleImportClose = () => {
    setImportOpen(false);
    refreshData();
  }

  const getSubStr = (str) => {
    if (str.length > 9) {
      var subStr1 = str.substr(0, 9);  
      str = subStr1 + "...";
    }
    return str;
  };

  const cardTouch = () => {
  }

  useEffect(async() => {
    if (state.isLogin) {
      const profile = await getProfile(state.userId);
      localStorage.setItem('is_nft',profile?.is_nft);
      const info = await getAuth();
      setProfile(profile);
      setAuthenticated(info.authenticated);
      fetchData();
    }
    if (state.isLogin === false) {
      navigate("/login");
    }
  },[state.isLogin])

  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = async() => {
    const data = await getNFTList(state.userId, 7, pageNum);
    setNFTList(NFTList.concat(data.nfts));
    setTotalNum(data.totalNum);
    if (pageNum > Math.floor(data.totalNum/7)) {
      setHasMore(false);
    } else {
      setPageNum(pageNum + 1);
    }
  }

  const refreshData = async() => {
    const data = await getNFTList(state.userId, 7, 1);
    setNFTList([]);
    setNFTList(data.nfts);
    setTotalNum(data.totalNum);
    setHasMore(true);
    setPageNum(2);
  }

  return (
    <div className="home">
      <div className="menu-bar">
        <img className="menu-button" src={menuButton} onClick={() => {sideBarRef.current.openSideBar()}} alt="" />
        <div className="flex flex-row">
          <img className="galaxy-logo" src={galaxyLogo} alt="" />
        </div>
      </div>
      <div className="main">
        <div className="profile">
          <HexagonAvatar hexagon={profile?.is_nft === 1} scale={0.8} src={smallAndProgressiveImage(profile?.avatarURL)} icon={false}/>
          <div className="profile-username">{profile.userName}</div>
          <div className="profile-introduction">{profile.introduction === "" ? "这个人很懒，什么都没有留下" : profile.introduction}</div>
          <div className="fuction-content">
            <div className="claim-button" onClick={handleClaim}><img src={claimIcon} alt="" />兑换</div>
            <div className="calendar-button" onClick={() => {navigate("/event")}}><img src={calendarIcon} alt="" />日历</div>
            <div className="import-button" onClick={handleImport}><img src={importIcon} alt="" />导入</div>
          </div>
        </div>
        <div className="list">
          <div className="list-label">
            <div className="title">
              <svg width="3" height="18" viewBox="0 0 3 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0C1.65685 0 3 1.34315 3 3V15C3 16.6569 1.65685 18 0 18V0Z" fill="url(#paint0_linear_249_6384)"/>
                <defs>
                <linearGradient id="paint0_linear_249_6384" x1="0.000204994" y1="8.95129" x2="2.99979" y2="8.95129" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6548E8"/>
                <stop offset="0.5" stopColor="#8B7BEF"/>
                <stop offset="1" stopColor="#6BC7FA"/>
                </linearGradient>
                </defs>
              </svg>
              <p>我的数字藏品</p>
            </div>
          </div>
          {NFTList?.length > 0 ? (
          <InfiniteScroll dataLength={NFTList.length} next={fetchData} hasMore={hasMore}
            loader={
              <p style={{ textAlign: 'center' }}>
                <b>加载中...</b>
              </p>
            }
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b/>
              </p>
            }
          >
            <div className="card-grid">
              {NFTList.map((nft, index) =>
                <Link to={"/nft/" + nft?.id} key={index} className="card-container" onMouseDown={cardTouch}>
                    {nft?.collectionId === 5 ?
                      <div className="nft-card">
                        {nft?.bot === 1 ?<div className="ai-nft">AI NFT</div>:null}
                        {/*<img className="nfo-image" src={smallAndProgressiveImage(nft?.thumbnailURL)} alt="" />*/}
                        <ProgressiveImage alt='' src={nft?.thumbnailURL}/>
                        {nft.type === "white" ?
                          <img className="biscuit" src={whiteBiscuit} alt="" />:
                          <img className="biscuit" src={blackBiscuit} alt="" />
                        }
                      </div>:
                      <div className="nft-card">
                        {nft?.bot === 1 ?<div className="ai-nft">AI NFT</div>:null}
                        {/*<img className="nft-image" src={smallAndProgressiveImage(nft.thumbnailURL)} alt="" />*/}
                        <ProgressiveImage alt='' src={nft?.thumbnailURL}/>
                      </div>
                    }
                  <div className="card-title">
                    <div className="name">{getSubStr(nft.name)}</div>
                    <div className="card-info">
                      <div className="avatar-name">
                        {/*<img src={smallAndProgressiveImage(profile?.avatarURL)} alt="" />*/}
                        <ProgressiveImage alt='' src={profile?.avatarURL}/>
                        <p>{profile.userName}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </InfiniteScroll>
          ):(
            <div className="no-content">
              <img src={noContentImg} alt="" />
              <div className="des">暂无您的NFT数据</div>
            </div>
          )}
        </div>
      </div>

      <div className="footerContainer">
        <div className='footerImg'>
          <div>
            <img src = {HomeIcon} alt='' className='leftImg' onClick={()=>{navigate('/')}}/>
          </div>
          <div>
            <img src = {LibraryIcon} alt='' className='middleImg' onClick={()=>{navigate('/library')}}/>
          </div>
          <div>
            <img src = {WalletIcon} alt='' className='rightImg' onClick={()=>{navigate('/homepage')}}/>
          </div>
        </div>
      </div>

      <Dialog className="claim-dialog" open={claimOpen}
        sx={{
          "& .MuiPaper-root": {
            width: "343px",
            height: "222px",
            background: "#282C49",
            borderRadius: "6px",
            overflow: "visible"
          }
        }}
      >
        <Claim/>
        <div className="dialog-close">
          <div className="close-line"/>
          <img className="close-icon" src={closeIcon} onClick={handleClaimClose} alt="" />
        </div>
      </Dialog>
      <AuthDialog authDialog={authOpen} setAuthDialog={setAuthOpen}/>
      <ImportDialog importDialog={importOpen} setImportDialog={setImportOpen} handleImportClose={handleImportClose} />
      <SideBar profile={profile} totalNum={totalNum} ref={sideBarRef}/>
    </div>
  )
}