import {portalAddress} from "../App";
import {Toast} from "antd-mobile";

export default function userUpdateProfile() {
    let headers = new Headers();
    headers.append("accessToken", localStorage.getItem("accessToken"));

    const updateProfile = async (username,introduction) => {
        try {
            const response = await fetch(
                portalAddress + "/user/" + localStorage.getItem("userId"),
                {
                    method: "POST",
                    headers: headers,
                    body:  JSON.stringify(
                        {
                            username: username,
                            introduction: introduction,
                        }
                    )
                });
            const json = await response.json();
            if (json.returnCode === "20000") {
                return json.data;
            } else {
                Toast.show({
                    content:json.msg,
                    position:"bottom"
                })
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return false;
        }
    }

    return updateProfile;
}