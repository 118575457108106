import './InputFooter.css';
import useDailyQuestion from '../../../utils/useDailyQuestion';
import useAnwser from '../../../utils/useAnswer';
import {useEffect, useRef, useState} from 'react';


export default function InputFooter({id, updateChat}){
  const [questionList, setQuestionList] = useState([]);
  const [question, setQuestion] = useState();
  const getQuestionList = useDailyQuestion();
  const getAnwser = useAnwser();
  const [conversition, setConversition] = useState();
  const [isAbleToSend, setIsAbleToSend] = useState(false);

  const sendQuestion = async (question) => {
    const answer = await getAnwser(id, question);
    if (question) {   
      answer.question = question;
    }
    setConversition(answer);
  }

  const inputOnchanged = (e) => {
    if (e.target.value.length > 0) {
      setIsAbleToSend(true);
    } else {
      setIsAbleToSend(false);
    }
    setQuestion(e.target.value);
  }

  const inputRef = useRef();

  const clear = () => {
    inputRef.current.value = "";
  }

  useEffect(() => {
    if (conversition) {
      updateChat(conversition);
    }
  },[conversition])

  useEffect(async () => {
    const list = await getQuestionList(id);
    setQuestionList(list);
  },[])
  
  return(
    <div className="inputFooter">
      <div className='question-list-container'>
        <div className='question-list'>
          {questionList?.map((question, index) => <div className='question' onClick={() => {sendQuestion(question)}} key={index}><span>{question}</span></div>)}
        </div>
      </div>
      <div className='input-container'>
        <input className='question-input-box' placeholder='请输入内容…' onChange={inputOnchanged} ref={inputRef}/>
        <button className={'send-button ' + (isAbleToSend? "able" : "disable")} onClick={() => {if (isAbleToSend) sendQuestion(question); clear(); setIsAbleToSend(false);}}>发送</button>
      </div>
    </div>
  )
}