import React, {useEffect, useRef, useState} from 'react';
import {smallAndProgressiveImage} from "../config";
import {Dialog} from '@mui/material';
import './shareDialog.css';
import qrCode from '../assets/user/qrCode.png';
import * as htmlToImage from "html-to-image";
import userShareOthersPoster from "../utils/userShareOthersPoster";
import background from "../assets/user/background.png";
import download from "../assets/user/download.png";
import {Toast} from "antd-mobile";

export default function ShareDialog(props) {
    const { setShareDialog, shareDialog, dialogList, userId, profile } = props;
    const [loadedCount, setLoadedCount] = useState(0);
    const stageRef = useRef();

    const loadedDone = () =>{
        setLoadedCount(loadedCount+1);
    }

    const displayPoster = (url) => {
        const parentDiv = document.getElementById('stageRef');
        if(parentDiv){
            parentDiv.innerHTML ='';
        }
        const result = document.createElement("img");
        result.style.minHeight = '100%';
        result.style.width = '100%';
        result.style.position = 'absolute';
        result.style.left = '0';
        result.src = url;
        stageRef.current.appendChild(result);
        result.onload = () =>{
            const mask = document.getElementById('unloaded');
            if (mask) {
                mask.remove();
            }
        }
    }

    useEffect(()=>{
        if(!shareDialog){
            setLoadedCount(0);
        }else{
            const getShareOthersPoster = userShareOthersPoster(userId);
                if(loadedCount === dialogList.length + 2 ){
                    htmlToImage.toBlob(stageRef.current).then(() => {
                        htmlToImage.toBlob(stageRef.current).then(() => {
                            htmlToImage.toBlob(stageRef.current).then(() => {
                                htmlToImage.toBlob(stageRef.current).then((blob) => {
                                    let img = new File([blob],'poster.png');
                                    let formData = new FormData();
                                    formData.append('file', img);
                                    getShareOthersPoster(formData).then((res)=>{
                                        displayPoster(res);
                                    })
                                });
                            })
                        });
                    });
                }
        }
    },[shareDialog,loadedCount])

    const close=()=>{
        setShareDialog(false);
        const parentDiv = document.getElementById('stageRef');
        if(parentDiv){
            parentDiv.innerHTML ='';
        }
    }

    return (
        <Dialog open={shareDialog} onClose={close}
                sx={{
                    "& .MuiDialog-container": {
                        backdropFilter: 'blur(2px)',
                    }}}
                PaperProps={{style: { borderRadius: 0 }}}>
            <div style={{background: 'white', position: 'absolute', zIndex: '2', width: '100%', height: '100%'}} id={'unloaded'}/>
            <img src={download} alt={''} style={{height: '24px', width: '24px', right: '15px', top: '12px', zIndex: '1', position:'absolute'}} onClick={()=>{
                Toast.show({
                    getContainer: document.getElementById('stageRef'),
                    content: '长按图片下载',
                    position: 'center'
                })
            }}/>
            <div className={'ShareDialogContainer'} ref={stageRef} id={'stageRef'} style={{backgroundImage: `url(${background})`}}>
                        <img className="profileAvatar"  src={profile?.avatarURL} alt="" onLoad={loadedDone} />
                        <div className={'nameContainer'}>{profile?.userName}</div>
                        <div className={'introductionContainer'}>
                            {profile?.introduction && profile?.introduction.length > 0 ? profile?.introduction : '这个人很懒，什么都没有留下'}
                        </div>
                        {
                            dialogList.length !== 0 ?   <div className={'listContainer'}>
                            {
                                dialogList && dialogList.map((item, index)=>{
                                    return(
                                        <div className={'nftContainer'} key={index}>
                                            <img src={smallAndProgressiveImage(item.thumbnailURL)} alt={''} onLoad={loadedDone}/>
                                        </div>
                                    )
                                })
                            }
                        </div>: null
                        }
                        {
                            dialogList.length === 0 ?
                            <img src={qrCode} className={'qrCodeFull'} alt = '' onLoad={loadedDone}/>
                            :<img src={qrCode} className={'qrCode'} alt = '' onLoad={loadedDone}/>
                        }
                    <div className={'scanText'} >扫一扫，在星际岛找到我</div>
                </div>
        </Dialog>
    )
}


