import {AuthContext, confluxAddress} from "../App";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import useCollectResult from "../utils/useCollectResult";
import "./CollectResult.css";
import successImage from "../assets/success.svg";
import failedImage from "../assets/failed.svg";
import loading from "../assets/loading.mp4";
import backImage from "../assets/back.svg";

export default function CollectResult() {
  const { state } = useContext(AuthContext);
  const [nftData, setNftData] = useState("nftCollecting");
  const [result, setResult] = useState();
  const getTxResult = useCollectResult();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const navigate = useNavigate();

  const getResult = () => {
    getTxResult(params.get("requestId")).then((res)=>{
      setNftData(res);
      switch (res.status) {
        case "nftCollecting":
          setResult({
            notice:"您正在通过Conflux Tethys网络提取NFT数字资产。提取正在进行中，请稍等......",
          })
          break;
        case "nftCollected":
          setResult({
            notice: "注意：您已通过Conflux Tethys网络提取NFT数字资产。XX卡包不再负责您的资产安全，请保管好自己的数字资产。",
            alert: "恭喜您！提取成功",
            buttonText: "链上查看",
            color: "blue",
            src: successImage,
          })
          break;
        case "nftFailedToCollect":
          setResult({
            notice: "提取失败，请前往「cocafe咖菲科技」公众号中留言，留言格式为“提取失败+手机号+提取地址”。",
            alert: "很遗憾！提取失败",
            buttonText: "重试",
            color: "red",
            src: failedImage,
          })
        default:
          break;
      }
    })
  }

  const buttonAction = () => {
    if (nftData.status === "nftCollected") {
      if (confluxAddress === "TESTNET") {
        window.location.href = "https://testnet.confluxscan.io/transaction/" + nftData.txHash;
      } else if (confluxAddress === "TETHYS") {
        window.location.href = "https://confluxscan.io/transaction/" + nftData.txHash;
      }
    } else if (nftData.status === "nftFailedToCollect") {
      navigate("/nft/" + nftData.id)
    }
  }

  useEffect(async ()=>{
    if (state.isLogin) {
      getResult();
      setTimeout(() => {
        getResult();
      }, 6000);
    }
  },[state.isLogin])

  return (
    <div className="collect-result">
    <img className="go-back" src={backImage} onClick={()=>{navigate(-1)}} alt=''/>
      <div className="headline">提取</div>
      <div className="notice-content">{result?.notice}</div>
      { 
        nftData.status === "nftCollecting" &&
        <div className="result-body">
          <div>
            <video className="loading-video" autoPlay loop muted playsInline> <source src={loading} type="video/mp4"/> </video>
            <div className="loading-text">提取中...</div>
          </div>
          <div className="refresh-notice">一直在加载？刷新试一试</div>
        </div>
      }
      {
        nftData.status !== "nftCollecting" &&
        <div className="result-body">
          <img className={"resualt-image" + " " + nftData.status } src={result?.src} alt=""/>
          <div className={"result-alert"+" "+result?.color}><p>{result?.alert}</p></div>
          { nftData.status==="nftCollected"&&
            <div className="nft-data">
              <table>
                <tr>
                  <td>藏品名称：</td>
                  <td className="table-value">{nftData.name}</td>
                </tr>
                <tr>
                  <td>藏品编号：</td>
                  <td className="table-value">#{nftData.tokenID}</td>
                </tr>
                <tr>
                  <td>提取地址：</td>
                  <td className="table-value">{nftData.address.substr(0, 18) + "..."}</td>
                </tr>
              </table>
            </div>
          }
          <button className={"action-button" + " " + result?.color} onClick={buttonAction}>{result?.buttonText}</button>
        </div>
      }
      <button className="back-button" onClick={()=>{navigate("/homepage")}}>返回卡包</button>
    </div>
  )
}