import {AuthContext, serverAddress} from "../App";
import {useContext} from "react";
import {Toast} from "antd-mobile";

export default function useCountByType() {
  const { state } = useContext(AuthContext);
  const countData = async(collectionId, type) => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        serverAddress + "/collection/" + collectionId + "/countByType?type=" + type,
        { method: "GET", headers: headers }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return json.data;
      } else {
        Toast.show({
          content:json.msg,
          position:"bottom"
        })
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return countData;
}