import './PaymentOrderPage.css';
import BackIcon from "../../assets/whiteBackButton.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Modal from './components/modal/Modal';
import CountDown from "../../components/countDown/CountDown";
import BankIcon from '../../assets/bankCardIcon.png';
import {bottomToast} from "../../utils/functionUtils";
import useCancelOrder from "../../utils/useCancelOrder";
import dayjs from "dayjs";
import {getOrderInfo} from "../bankCardManagePage/api";
import UseCreateOrder from "../../utils/useCreateOrder";
import {clickIWant} from "../commonDeatilsPage/api";

export default function PaymentOrderPage(){
    const [dialog, setDialog] = useState(false);
    const [info, setInfo] = useState();
    const [diffSeconds, setDiffSeconds] = useState();
    const [order, setOrder] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const cancelOrder = useCancelOrder();
    const createOrder = UseCreateOrder();

    const releaseId = localStorage.getItem('releaseId');
    const collectionId = localStorage.getItem('collectionId');

    const toCancelOrder = () =>{
        cancelOrder(info?.orderNumber).then((res)=>{
            if(res?.hasCancelled === true){
                bottomToast('订单已取消');
                navigate('/paymentOrderList');
            }
        })
    }

    const goBack = () =>{
        navigate(`/commonDetails/${releaseId}`);
    }

    const cardMange = ()=>{
        localStorage.setItem('orderUrl',location.pathname);
        navigate('/cardManage');
    }

    const payment = () => {
        if(info?.userBankCard){
            if (!order) {
                createOrder(releaseId, collectionId).then((res) => {
                    if(res){
                        document.myform.action = res.gatewayUrl;
                        document.myform.submit();
                    }
                })
            } else {
                document.myform.action = info.gatewayUrl;
                document.myform.submit();
            }
        }else{
            bottomToast('请先绑定银行卡')
        }
    }

    useEffect(()=>{
        const path = location?.pathname?.replace('/payment/','');

        if(location?.search?.includes('paid')){
            setDialog(true);

            let url = window.location.href;
            let variable = url.split("?")[0];
            window.history.pushState({},0,variable);
        }

        if(location.pathname === '/payment/undefined'){
                clickIWant(releaseId, collectionId).then((res)=>{
                   setInfo(res.data.data);
                })
        }else{
            getOrderInfo(path).then((res)=>{
                if(res.data.returnCode === '20000'){
                    setOrder(true);
                    setInfo(res.data.data);
                    setDiffSeconds(dayjs(res?.data?.data?.expiredAt).diff(dayjs(),'seconds'));
                }else{
                    bottomToast(res.data.msg);
                }
            })
        }

      const countDown =  setTimeout(()=>{
            setDiffSeconds(0)
        }, diffSeconds * 1000)

        return ()=> clearTimeout(countDown);
    },[])

    useEffect(()=>{
        const countDown =  setTimeout(()=>{
            setDiffSeconds(0)
        }, diffSeconds * 1000)

        return ()=> clearTimeout(countDown);
    },[diffSeconds])

    return(
        <div className='paymentOrderPageContainer'>
            <Modal visible={dialog} setDialog={setDialog} info={info}/>
            <form name="myform" method="post"/>
           <div className='content'>
               <img src={BackIcon} style={{ position: 'absolute', left: '0', top: '0',height: '17px', margin: '20px'}} onClick={goBack} alt=""/>
               <div className='header'>填写订单</div>
                   <div className='waringText'>
                       {!order? null : info?.orderStatus === 'orderNotPayed' && diffSeconds> 0? '请尽快完成支付，超时订单自动取消':
                       info?.orderStatus === 'orderCompleted'? '支付成功': '订单超时，已自动取消' }
                   </div> 
               {
                   !order? null: info?.orderStatus === 'orderNotPayed' && diffSeconds> 0?
                   <CountDown hours={`${diffSeconds/3600}`} minutes={`${(diffSeconds%3600)/60}`} seconds={`${diffSeconds%60}`} type={'payment'}/> : null
               }
               <div className='avatar'>
                   <div className='avatarImg'>
                       <img src ={info?.displayURL}   alt= ''/>
                   </div>
               </div>
               <div className='paymentContainer'>
                    <div className='paymentTitle'>下单人信息：</div>
                   <div className='paymentContentContainer'>
                       <div className='paymentSubtitle'>手机号码</div>
                       <div className='paymentContent'>{info?.userPhone}</div>
                   </div>
                   <div className='paymentContentContainer'>
                       <div className='paymentSubtitle'>购买人</div>
                       <div className='paymentContent'>{info?.userName}</div>
                   </div>
                   <div className='paymentLine'/>
                   <div className='paymentTitle'>支付方式：</div>
                   <div className='paymentContentContainer'>
                       <div className='bankIconContent'>
                           <img src={BankIcon} style={{height: '24px', marginRight: '10px'}} alt=""/>
                           <div className='paymentSubtitle'>{info?.userBankCard ?<BackCardDisplay cardNumber={info?.userBankCard}/>:'添加银行卡'}</div>
                       </div>

                       <div className='paymentColorContent' onClick={cardMange}>
                           { info?.userBankCard? '去修改': '去添加' }
                       </div>
                   </div>
               </div>
           </div>
            <div className='footer'>
                <div className='priceContainer'>
                    <div className='priceTitle'>价格</div>
                    <div className='priceText'>¥ {info?.price}</div>
                </div>
                <div className='buttonContainer'>
                    {
                        !order ? <div className='paymentButton' onClick={payment}>去支付</div> :
                            info?.orderStatus === 'orderCompleted' ?
                                <div className='paymentButton' onClick={() => {setDialog(true)}}> 支付完成</div>
                                : !diffSeconds > 0 || info?.orderStatus === 'orderCanceled' ?
                                    <div className='timeoutButton'>已超时</div> :
                                  info?.orderStatus === 'orderClosed'?
                                      <div className='timeoutButton'>已关闭</div> :
                                    <>
                                        <div className='cancelButton' onClick={toCancelOrder}>取消订单</div>
                                        <div className='paymentButton' onClick={payment}>去支付</div>
                                    </>
                    }
               </div>
            </div>
        </div>
    )
}

const BackCardDisplay = (props) =>{
    const {cardNumber} = props;
    const splitCardNumber = cardNumber?.replace(/(.{4})/g, "$1 ")

    return(
        <div>
            {splitCardNumber}
        </div>
    )
}

