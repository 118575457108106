import {React} from 'react';
import {Dialog} from '@mui/material';
import './AuthDialog.css';
import {useNavigate} from 'react-router-dom';
import closeIcon from "../../../assets/close-icon.svg";


export default function AuthDialog(props) {
  const navigate = useNavigate();
  const { setAuthDialog, authDialog} = props;
  return (
    <Dialog className='auth-dialog' sx={{"& .MuiDialog-paper": {width: "343px", height: "222px", background: "#282C49", borderRadius: "6px", overflow: 'visible'}}} open={authDialog} onClose={()=> {setAuthDialog(false)}} >
      <div className="claim">
        <div className="claim-container">
          <div className="title">
            实名认证
          </div>
          <div className="divider" />
          <div className="content">
            您还没有绑定身份信息<br/>
            为保障你的账户安全请及时绑定
          </div>
          <div className="control">
            <button className="confirm" onClick={() => {navigate("authentication")}} >去认证</button>
          </div>
        </div>
      </div>
      <div className="dialog-close">
        <div className="close-line"/>
        <img className="close-icon" src={closeIcon} onClick={() => {setAuthDialog(false)}} alt="" />
      </div>
    </Dialog>
  )
}
