import {forwardRef, useImperativeHandle, useState} from 'react';
import {Dialog} from '@mui/material';
import "./Agreement.css";
import closeButton from "../assets/close-button.svg";

function Agreement(props, ref) {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openAgreement() {
      setOpen(true);
    }
  }), [])

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <div className="agreement">
        <div className="about-title relative flex justify-center mt-10 mb-10">
          <img className="absolute left-4" width="12.83" height="12.72" src={closeButton} onClick={()=>{setOpen(false)}} alt=''/>
          《用户协议》
        </div>
        <div className="agreement-content">
          <p className="font-semibold">一、用户协议条款的确认和修改</p>
          <p>1.	本用户协议条款（以下简称“用户协议”）是由用户（您）与上海咖菲信息科技有限公司（咖菲科技）订立，并适用于咖菲科技提供的网站（https://galaxy.cocafe.co）。</p>
          <p>2.	用户在使用咖菲科技提供的网络服务(包括但不限于咖菲科技网站)前有义务仔细阅读本协议。用户在登录、领取或使用咖菲科技提供、展示的任何藏品和服务即表示用户完全接受本协议项下的全部条款。本协议即构成对咖菲科技和用户双方之间均具有法律约束力的正式文件。</p>
          <p>3.	用户在此不可撤销地承诺，若其使用咖菲科技提供的网络服务，将视为用户同意并接受本协议全部条款的约束，此后用户无权以未阅读本协议或对本协议有任何误解为由，主张本协议无效或要求撤销本协议。</p>
          <p>4.	本协议可由咖菲科技随时更新，且无须另行通知。用户协议一旦发生变更,咖菲科技将在咖菲科技网站上公布修改内容。修改后的用户协议一旦在咖菲科技网站上公布即有效代替原来的用户协议。您可随时打开网站查阅最新版用户协议。用户如果不同意修改后的本协议的任何条款，可以放弃访问咖菲科技相关网页、网站；如果用户选择继续接受咖菲科技提供的服务，则视为用户已经完全接受用户协议及其相关修改。</p>
          <p>5.	您了解咖菲科技网站上指引、提及的第三方网页、网站为用户自行发布或其他商家提供发布，且可能存在风险和瑕疵。您应自行谨慎判断确定相关藏品和/或信息的真实性、合法性和有效性，并自行承担因此产生的责任与损失。</p>
          <p className="font-semibold">二、用户登录</p>
          <p className="font-medium">1、登录资格</p>
          <p>您，用户应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的监护人应承担因此而导致的一切后果，且咖菲科技有权注销（永久冻结）您的咖菲科技账户，并向您及您的监护人索偿。</p>
          <p className="font-medium">2、账户</p>
          <p>在您签署本协议，完成用户登录程序或以其他咖菲科技允许的方式实际使用咖菲科技网络服务时，您的手机号即为咖菲科技网站账户（以下亦称账户）。您登录的手机号不得侵犯或涉嫌侵犯他人合法权益。用户需要在网站进行短信验证并授权绑定手机号作为领取区块链数字藏品（“数字藏品”）以及提取数字藏品的唯一凭证。特别提示：用户的手机号请务必自行妥善保管。因用户个人原因泄露、遗失、转号而导致数字藏品遗失或被盗或冒领的后果及损失由该用户自行承担。</p>
          <p>除非有法律规定或司法裁定，且征得咖菲科技的同意，否则，账户和短信验证码不得以任何方式转让、赠与或继承。如果发现任何人不当使用您的账户或有任何其他可能危及您的账户安全的情形时，您应当立即以有效方式通知咖菲科技，要求咖菲科技暂停相关服务，并提供所需凭证。您理解咖菲科技对您的请求采取行动需要合理时间，咖菲科技对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。</p>
          <p>为方便您使用咖菲科技服务及咖菲科技关联公司或其他组织的服务（以下称其他服务），您同意并授权咖菲科技将您在登录、使用咖菲科技服务过程中提供、形成的信息传递给向您提供其他服务的咖菲科技关联公司或其他组织，或从提供其他服务的咖菲科技关联公司或其他组织获取您在登录、使用其他服务期间提供、形成的信息。</p>
          <p className="font-medium">3、权益</p>
          <p>用户登录后可查看其拥有的区块链数字藏品，每一份数字藏品都是非卖品，无市场价格，不允许用户因个人原因申请退款。数字藏品仅可用于您的个人收藏，学习，研究，欣赏和展示之目的，不可转赠、不可售卖，或以其它任何形式进行转让，亦不可用于任何商业行为，并且严格禁止炒作、场外交易、欺诈等任何非法用途，由此所产生违法及违约责任由用户自行承担。</p>
          <p>用户需提供自有的数字钱包地址以领取其拥有的数字藏品至地址对应的数字钱包，在本站点领取数字藏品后，可在用户数字钱包内查看所持有的数字藏品，数字钱包的开通、指引、地址获取见第三方网站，未来咖菲科技会提供更多的平台展示及功能，具体消息请关注本网站的更新信息。</p>
          <p>用户在提取后需妥善保管自己的数字藏品和自己的区块链钱包私钥。提取后发生的一切遗失、失窃、无法找回等情形，均由用户自行承担。用户领取数字藏品后，利用该数字藏品进行的违约、违法行为，由用户个人承担相关法律责任。</p>
          <p className="font-semibold">三、用户行为规范</p>
          <p>在使用咖菲科技服务过程中，您承诺遵守下列使用规范：</p>
          <p>1、基于区块链技术的特性，区块链数字藏品具有唯一、不可篡改的特点，数字藏品中的数字内容，铸造时间等信息无法篡改。在您获得数字藏品后，您与数字藏品的所有权等权利关系将通过智能合约记录在区块数据中。</p>
          <p>2、您理解并同意，您领取数字藏品后，只拥有该数字藏品本身的所有权，不拥有该数字藏品对应的原艺术品的所有权及包括著作权在内的各项知识产权，亦不拥有该数字藏品的知识产权。</p>
          <p>3、您理解并同意，基于维护网络秩序和安全，如我们发现您存在违法违规或违反本协议约定情形的，我们有权采取同时采取一项或多项措施，包括但不限于： A) 要求您限期纠正违规或违约行为； B) 屏蔽或限制您使用数字藏品； C) 对违法违规信息进行删除或屏蔽，取消您的领取资格或数字藏品对应的相关权益 ；D) 停止或终止向您提供部分或全部服务； E) 其他合理、合法的措施。 我们因任何原因未采取上述任何措施，不代表我们放弃未来采取该等措施的权利。如您的行为使我们遭受损失的，我们还有权要求您赔偿我们的全部损失，包括商誉损失、赔偿金、律师费、诉讼费等。</p>
          <p>4、您理解并同意，在您获得数字藏品和使用本服务的过程中，可能会遇到不可抗力等风险因素。如出现下列任一情况致使我们无法履行本协议约定的，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您或第三方造成的损失，我们不承担责任： A) 在与本服务相关的系统停机维护或升级期间； B) 因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化和政府行为等不可抗力原因； C) 您操作不当或通过非我们授权或认可的方式使用本服务； D) 因病毒、木马、恶意程序攻击网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、电信设备故障、第三方服务瑕疵等原因。</p>
          <p>5、您理解并同意，鉴于网络服务的特殊性，我们对本服务的持续提供可能受到多种因素的影响，如出现技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化。我们可能随时终止、中断提供服务或变更所提供服务的形式、规格或其他方面，而您不会因此追究我们的责任。</p>
          <p>6、网站上的所有内容，包括但不限于文字、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计等，均由知识产权人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经我们书面同意，您不得擅自使用、修改、反向编译，复制，公开传播、改变、散布、发行或公开发表、转让、许可、商业利用本网站的相关软件、内容以及知识产权人享有的知识产权。否则我们有权立即终止本协议并要求您停止违约和/或侵权行为，您应对您的违约或侵权行为给我们或其他权利人造成的损失承担相应赔偿责任。</p>
          <p>7、为进一步改善用户体验，我们将联合我们的关联方和/或合作方持续更新服务，包括但不限于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。我们也会根据国家法律法规的变化要求，更新本协议的相关条款。更新的内容将于协议更新公告公布的生效之日起生效。若您不接受变更后的服务内容或功能，或不同意更新后的协议内容，您应立即停止使用相关服务。若您继续使用本协议相关服务的，即视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束。</p>
          <p>8、用户不应将其帐号、密码转让或出借予他人使用。如用户发现其帐号遭他人非法使用，应立即通知咖菲科技。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，咖菲科技不承担任何责任。</p>
          <p>9、用户同意咖菲科技有权在提供网络服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在咖菲科技网站的任何页面上发布宣传、推广信息），并且用户同意接受咖菲科技通过短信、电子邮件或其他方式向用户发送藏品或其他相关商业信息。</p>
          <p className="font-semibold">四、咖菲科技服务使用规范</p>
          <p>1、咖菲科技有权对您是否违反上述承诺做出认定，并根据认定结果适用规则予以处理或终止向您提供服务，且无须征得您的同意或提前通知予您。</p>
          <p>2、经国家行政或司法机关的生效法律文书确认您存在违法或侵权行为，或者咖菲科技根据自身的判断，认为您的行为涉嫌违反本协议和/或规则的条款或涉嫌违反法律法规的规定的，则咖菲科技有权在咖菲科技上公示您的该等涉嫌违法或违约行为及咖菲科技已对您采取的措施。</p>
          <p>3、对于您涉嫌违反承诺的行为对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保咖菲科技免于因此产生损失或增加费用。如咖菲科技因此承担相应责任或者赔偿相关损失，则您承诺咖菲科技可以向您追偿，相关责任或损失由您最终承担，相关损失包括合理的律师费用、相关机构的查询费用等。</p>
          <p>4、如您涉嫌违反有关法律或者本协议之规定，使咖菲科技遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿咖菲科技因此造成的损失及（或）发生的费用，包括合理的律师费用、相关机构的查询费用等。</p>
          <p>5、咖菲科技上展示的资料（包括但不限于文字、图表、标识、图像、数字下载和数据编辑）均为咖菲科技或其内容提供者的财产或者权利；咖菲科技上所有内容的汇编是属于咖菲科技的著作权；咖菲科技上所有软件都是咖菲科技或其关联公司或其软件供应商的财产或者权利，上述知识产权均受法律保护。如您侵犯上述权利，咖菲科技有权根据规则对您进行处理并追究您的法律责任。</p>
          <p className="font-semibold">五、特别授权</p>
          <p>您完全理解并不可撤销地授予咖菲科技及其关联公司下列权利：</p>
          <p>1、一旦您向咖菲科技及（或）其关联公司，作出任何形式的承诺，且相关公司已确认您违反了该承诺，则咖菲科技有权立即按您的承诺或协议约定的方式对您的账户采取限制措施，包括中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，咖菲科技无须就相关确认与您核对事实，或另行征得您的同意，且咖菲科技无须就此限制措施或公示行为向您承担任何的责任。</p>
          <p>2、一旦您违反本协议，或与咖菲科技签订的其他协议的约定，咖菲科技有权以任何方式通知咖菲科技关联公司，要求其对您的权益采取限制措施，包括但不限于要求咖菲科技将您持有的数字藏品转移给咖菲科技指定的对象，要求关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。</p>
          <p>3、您授权咖菲科技可获取您登录手机号（账户）下的所有区块链数字藏品并将其在咖菲科技网站展示，所述区块链数字藏品可铸造在包括但不限于conflux，至信链，蚂蚁链、趣链上。</p>
          <p className="font-semibold">六、免责声明</p>
          <p>1. 咖菲科技将通过各种手段尽力核实第三方网站的相关信息，但无法保证设置的外部链接的准确性和完整性，也无法保证合作商家的安全性，同时对于该等外部链接指向的不由咖菲科技实际控制的任何网页上的内容，均不承担任何责任。</p>
          <p>2. 对于因不可抗力或咖菲科技不能控制的原因造成的服务中断或其它缺陷，咖菲科技不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
          <p>3. 用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，咖菲科技不承担任何责任。</p>
          <p>4. 咖菲科技依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成咖菲科技的义务或承诺，咖菲科技不能保证及时发现违法违规或违约行为或进行相应处理。</p>
          <p className="font-semibold">七、协议终止</p>
          <p>1、您同意，咖菲科技有权自行全权决定以任何理由不经事先通知的中止、终止向您提供部分或全部咖菲科技平台服务，暂时冻结或永久冻结（注销）您的账户，且无须为此向您或任何第三方承担任何责任。</p>
          <p>2、出现以下情况时，咖菲科技有权直接以注销账户的方式终止本协议:</p>
          <p>a) 咖菲科技终止向您提供服务后，您涉嫌再一次直接或间接或以他人名义注册为咖菲科技用户的；</p> 
          <p>b) 本协议（含规则）变更时，您明示、默示并通知咖菲科技不愿接受新的服务协议的；</p>
          <p>c) 其它咖菲科技认为应当终止服务的情况；</p>
          <p>3、您有权向咖菲科技要求注销您的账户，经咖菲科技审核同意的，咖菲科技注销（永久冻结）您的账户，届时，您与咖菲科技基于本协议的合同关系即终止。您的账户被注销（永久冻结）后，咖菲科技没有义务为您保留或向您披露您账户中的任何信息，并有权处理您的数字藏品。</p>
          <p>4、您同意，您与咖菲科技的合同关系终止后，咖菲科技仍享有下列权利：</p>
          <p>a) 继续保存您的登录信息。</p>
          <p>b) 您在使用咖菲科技服务期间存在违法行为或违反本协议和/或规则的行为的，咖菲科技仍可依据本协议向您主张权利。</p>
          <p className="font-semibold">八、隐私权政策</p>
          <p>1、咖菲科技对希望用户没有任何限制，但16岁以下的用户使用咖菲科技服务必须取得监护人的同意；</p>
          <p>2、一个手机号仅限一名用户使用，用户须向咖菲科技提供真实确实的信息，对于由于资料提供不正确导致汇款无法领取等后果，咖菲科技不承担责任；</p>
          <p>3、用户必须遵守咖菲科技（及合作伙伴）的使用条款及隐私政策。</p>
          <p>九、法律适用、管辖与其他</p>
          <p>1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。</p>
          <p>2、因本协议产生之争议，应依照中华人民共和国法律予以处理。双方对于争议协商不成的，应当提交上海市静安区人民法院诉讼解决。</p>
          <p>3、在法律允许的范围内，以上说明的最终解释权归咖菲科技所有。咖菲科技保留任何对针对用户使用本网站细则的修改，更新和迭代的权利。具体请见公示。</p>
        </div>
      </div>
    </Dialog>
  )
}

export default forwardRef(Agreement)