import {AuthContext} from "../App";
import {smallAndProgressiveImage} from "../config";
import {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import useCollectionList from "../utils/useCollectionList";
import noContentImg from "../assets/no-content.png";
import InfiniteScroll from "react-infinite-scroll-component";
import blackBiscuit from "../assets/biscuit.png";
import whiteBiscuit from "../assets/biscuit-white.png";
import numberIcon from "../assets/number.svg";
import ownerIcon from "../assets/owner.svg";
import backButton from "../assets/back.svg";
import "./CollectionList.css";
import useCountByType from "../utils/useCountByType";

export default function CollectionList() {
  const { state } = useContext(AuthContext);
  const [CollectionList, setCollectionList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const getCollectionList = useCollectionList();
  const getCountData = useCountByType();
  const [count, setCount] = useState();
  const [collectionName, setColelctionName] = useState();
  const navigate = useNavigate();
  const params = useParams();
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);

  const getSubStr = (str) => {
    if (str.length > 9) {
      var subStr1 = str.substr(0, 9);
      str = subStr1 + "...";
    }
    return str;
  };

  const getExtension = (name) => {
    return name.substring(name.lastIndexOf("."))
  }

  useEffect(async() => {
    fetchData();
  },[state.isLogin])

  const fetchData = async() => {
    const countData = await getCountData(params.id, searchParams.get("type"));
    setCount(countData);
    const data = await getCollectionList(params.id, 8, pageNum, searchParams.get("type"));
    setCollectionList(CollectionList.concat(data.nfts));
    setColelctionName(countData.name);
    if (pageNum > Math.floor(data.totalNum/7)) {
      setHasMore(false);
    } else {
      setPageNum(pageNum + 1);
    }
  }

  return (
    <div className="collection-list">
      <div className="header">
        <img className="back" src={backButton} alt="back" onClick={()=>{navigate(-1)}}/>
        <div className="menu-title">{collectionName}</div>
      </div>
      <div className="volume-data">
        <div className="count-container">
          <img src={numberIcon} alt=""/>
          <div>
            <div className="number">{count?.amount}</div>
            <div>数量</div>
          </div>
        </div>
        <div className="divider"/>
        <div className="count-container">
          <img src={ownerIcon} alt=""/>
          <div>
            <div className="number">{count?.users}</div>
            <div>拥有者</div>
          </div>
        </div>
      </div>
      <div className="list">
        {CollectionList.length > 0 ? (
        <InfiniteScroll dataLength={CollectionList.length} next={fetchData} hasMore={hasMore} 
          loader={
            <p style={{ textAlign: 'center' }}>
              <b>加载中...</b>
            </p>
          }
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b/>
            </p>
          }
        >
          <div className="card-grid">
            {CollectionList.map((nft, index) => 
              <Link to={"/nft/" + nft.id} key={index} className="card-container">
                {nft.collectionId === 5 ?
                  <div className="nft-card">
                    <img className="nfo-image" src={smallAndProgressiveImage(nft.thumbnailURL)} alt=""/>
                    {nft.type === "white" ?
                      <img className="biscuit" src={whiteBiscuit} alt=""/>:
                      <img className="biscuit" src={blackBiscuit} alt=""/>
                    }
                  </div>:
                  <div className="nft-card">
                    {(() => {
                      const ext = getExtension(nft.thumbnailURL)
                      switch (ext) {
                        case ".png": case ".PNG": case ".jpg":  case ".JPG": case ".jpeg": case ".JPEG":
                          return <img src={smallAndProgressiveImage(nft.thumbnailURL)} alt=""/>
                        case ".mp4":
                          return (<video className="nft-image" poster={smallAndProgressiveImage(nft.thumbnailURL)} autoPlay loop muted playsInline>
                                    <source src={nft?.thumbnailURL} />
                                  </video>)
                        default:
                          break;
                      }
                    })()}
                  </div>
                }
                <div className="card-title">
                  <div className="name">{getSubStr(nft.name)}</div>
                  <div className="card-info">
                    <div className="avatar-name">
                      <img src={smallAndProgressiveImage(nft.ownerUserInfo?.avatarURL)} alt=""/>
                      <p>{nft.ownerUserInfo?.userName}</p>
                    </div>
                  </div>
                </div>
              </Link>
            )}
          </div>
        </InfiniteScroll>
        ):(
          <div className="no-content">
            <img src={noContentImg} alt=""/>
            <div className="des">暂无您的NFT数据</div>
          </div>
        )}
      </div>
    </div>
  )
}