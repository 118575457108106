import backButton from "../../assets/back.svg";
import {AuthContext} from "../../App";
import {useNavigate, useParams} from "react-router";
import useSeries from "../../utils/useSeries";
import {useContext, useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import arLogo from "./assets/ar-logo.svg";
import "./index.css";
import {smallAndProgressiveImage} from "../../config";

export default function SeriesPage () {
  const params = useParams();
  const galleryId = params.id;
  const [series, setSeries] = useState([])
  const [shortName, setShortName] = useState()
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [bannerImage, setBannerImage] = useState();
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  const getSeries = useSeries();

  const fetchData = async() => {
    if (state.isLogin) {
      const data = await getSeries(7, pageNum, galleryId);
      setSeries(series.concat(data.series));
      if (data?.series.length > 0) {
        setBannerImage(data.series[0].seriesImage);
      }
      setShortName(data.galleryName);
      if (pageNum > Math.floor(100/7)) {
        setHasMore(false);
      } else {
        setPageNum(pageNum + 1);
      }
    }
    if (state.isLogin === false) {
      navigate("/login");
    }
  }

  useEffect(() => {
    fetchData();
  },[state.isLogin])

  const handleScroll = () => {
    document.querySelectorAll(".series-card").forEach((item) => {
      if (item.getBoundingClientRect().top > 0 && item.getBoundingClientRect().top < 426) {
        const image = item.firstChild.style.backgroundImage;
        document.querySelector("#banner").style.backgroundImage = `${image}`
      }
    })
    if (document.documentElement.scrollTop > 0) {
      document.querySelector("#header").style.display = "none";
    } else {
      document.querySelector("#header").style.display = "flex";
    }
  }

  return (
    <div className="series-page">
      <div className="banner" id="banner" style={{backgroundImage: `url(${smallAndProgressiveImage(bannerImage)})`, backgroundSize: "cover"}} />
      <div className="banner-filter" />
      <div className="header" id="header" >
        <img className="back" src={backButton} alt="back" onClick={()=>{navigate(-1)}}/>
        <div className="name">{shortName}</div>
      </div>
      <div className="main">
        <InfiniteScroll dataLength={series.length} next={fetchData} hasMore={hasMore} onScroll={handleScroll} loader={null}>
          <div className="card-list">
            {series.map((item, index) =>
              <SeriesCard title={item?.seriesName} image={item.seriesImage} description={item.seriesDescription} hasAR={item.hasAR} seriesNum={item.seriesNum} key={index} />
            )}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  )
}

function SeriesCard(params) {
  const navigate = useNavigate();
  const getSubStr = (str, length) => {
    if (str.length > length) {
      var subStr1 = str.substr(0, length);
      var subStr = subStr1 + "...";
      return subStr;
    } else {
      return str;
    }
  };

  const toDetailsPage = ()=>{
    navigate(`/seriesDetails/${params.seriesNum}`)
  }
  return (
    <div className="series-card">
      <div className="series-cover" style={{background: `url(${smallAndProgressiveImage(params.image)})`, backgroundSize: "cover", backgroundPosition: "center"}} onClick={toDetailsPage}/>
      <div className="series-intro" onClick={toDetailsPage}>
        <div className="series-title">
          <p>《{getSubStr(params.title, 10)}》</p>
          {
            params.hasAR ? 
            <img src={arLogo}  alt="" /> :
            null
          }
        </div>
        <div className="series-description">
          {getSubStr(params.description, 38)}
        </div>
      </div>
    </div>
  )
}