import {Toast} from "antd-mobile";

export function GetQueryValue(queryName) {
    let query = decodeURI(window.location.search.substring(1));
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] === queryName) {
            return pair[1];
        }
    }
    return null;
}

export function stopBodyScroll (isFixed) {
    let bodyEl = document.body;
    let top = 0;

    if (isFixed) {
        top = window.scrollY

        bodyEl.style.position = 'fixed'
        bodyEl.style.top = -top + 'px'
    } else {
        bodyEl.style.position = ''
        bodyEl.style.top = ''

        window.scrollTo(0, top)
    }
}

export const phoneReg=/^[1][0-9]{10}$/;

export  const bottomToast = (text) => {
    Toast.show({
        content: text,
        position: 'bottom',
        duration: 2000
    })
}
