import './SeriesDetails.css';
import {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import Icon from './assets/arIcon.png';
import PlayIcon from './assets/playIcon.png';
import backButton from '../../assets/back.svg';
import {GetListBySeries} from "./api";
import {useNavigate} from "react-router";
import {bottomToast} from "../../utils/functionUtils";
import {useParams} from "react-router-dom";
import {Dialog} from "@mui/material";
import {smallAndProgressiveImage} from '../../config.js';
import ProgressiveImage from "../../components/progressiveImage/ProgressiveImage";

export default function SeriesDetails() {
    const [text, setText] = useState(1);
    const [info, setInfo] = useState(undefined);
    const [videoDialog, setVideoDialog] = useState(false);
    const [videoVisible, setVisible] = useState(false);
    const [copyFlag, setCopyFlag] = useState(false);

    const navigate = useNavigate();
    const params = useParams();

    const displayText = (text) =>{
        if(text < 10){
            return `0${text}`;
        }else{
            return text;
        }
    }

    const back = ()=>{
        navigate(-1);
    }

    const toArShow = ()=>{
        if(info?.collections[text-1]?.ARDisplay?.length > 0){
            navigate("/ar?url=" + info.collections[text-1].ARDisplay)
        }else{
            bottomToast('此NFT暂无AR展馆');
        }
    }

    useEffect(()=>{
        GetListBySeries(params.id).then((res)=>{
            if(res?.data?.returnCode === '20000'){
                // swiper在全部slide宽度小于容器宽度的时候启用loop会产生部分错位，建议把数组个数翻倍，目前的设备测试为2
                if(res?.data?.data.collections?.length === 2){
                    setInfo({...res.data.data, collections: [...res.data.data.collections,...res.data.data.collections]});
                    setCopyFlag(true);
                }else{
                    setInfo(res?.data?.data);
                }
            }else{
                bottomToast(res?.data?.msg);
            }
        })
    },[]);

    useEffect(()=>{
        if(videoDialog){
           const timeout = setTimeout(()=>{
                setVisible(true);
            },500);
            return ()=> clearTimeout(timeout);
        }else if(!videoDialog){
            setVisible(false);
        }
    },[videoDialog])

    useEffect(()=>{
        if(videoVisible){
            const video = document.getElementById('video');
            video.muted = false;
            video.loop = true;
            video.play();
        }
    },[videoVisible])

    return(
        <div className='allSetContainer'>
            <Dialog fullScreen open={videoDialog}
                    PaperProps={{style: {backgroundColor: 'black', display:'flex',alignItems:'center',justifyContent:'center'}}}>
                <img src={backButton}
                     alt=""
                     style={{position: 'absolute', height: '26px', top:'16px', left: '16px', zIndex: '999'}}
                     onClick={()=>{setVideoDialog(false)}}
                />
                {
                    info?.collections ?
                        <div className='video-container' style={{visibility: videoVisible ? 'visible' : 'hidden'}}>
                            <video controls playsInline id='video' width='100%'>
                                <source src={info?.collections[text - 1]?.displayURL} type='video/mp4'/>
                            </video>
                        </div> : null
                }
            </Dialog>

            <img src={backButton} style={{position: 'absolute', height: '26px', margin: '16px', left: '0', zIndex: '4'}} onClick={back} alt=""/>
            {
                info?.collections?.map((item,index)=>{
                  return(
                      <div key={index}>
                          <div className='topPicContainer'
                               key={`pic-${index}`}
                               style={{
                                   backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(15, 23, 42, 1)), url(${smallAndProgressiveImage(item?.thumbnailURL)})`,
                                   opacity: text === index + 1 ? '0.7' : '0'
                               }}/>
                          <div className='seriesAndAuthorContainer' style={{opacity: text === index + 1 ? '1' : '0'}} key={`series-${index}`}>
                              #{info?.seriesName} #{item?.creator}
                          </div>
                          <div className='nameContainer' style={{opacity: text === index + 1 ? '1' : '0'}} key={index}>
                              {item?.name}
                          </div>
                      </div>
                  )})
            }
            <div className='topSetContainer'>
                <div className='countdownContainer'>
                    <div>
                        {
                            info?.collections?.map((item, index) => {
                                return (
                                    <div className='text' key={index} style={{opacity: text === index + 1 ? '1' : '0'}}>
                                        {copyFlag?displayText(index + 1 ===3? 1: index + 1 === 4 ?2 : index +1):displayText(index + 1)}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='line'/>
                    <div className='totalNumber'>{copyFlag?displayText(info?.collections?.length/2): displayText(info?.collections?.length) }</div>
                </div>
                <div className='swiperContainer'>
                    {
                        info?.collections?.length > 0?
                            <Swiper
                            slidesPerView='auto'
                            spaceBetween={22}
                            className="mySwiper"
                            centeredSlides={false}
                            loop={info?.collections?.length !== 1}
                            onSlideChange={(e)=>{setText(e.realIndex+1)}}>
                            {
                                info?.collections?.map((item, index) => {
                                    return (<SwiperSlide key={index}><Card info={item} index={index} currentIndex={text} setVideoVisible={setVideoDialog}/></SwiperSlide>)
                                })
                            }
                        </Swiper>: null
                    }

                    <img src ={Icon} className='icon' onClick={toArShow} alt=""/>
                </div>
            </div>
            <div className='processContainer'>
                <div className='bar'
                     style={!copyFlag ? {
                             width: `calc((100% - 10px)/${info?.collections?.length})`,
                             left: `calc(5px + ${text - 1}* calc((100% - 10px)/${info?.collections?.length}))`
                         } :
                         {
                             width: `calc((100% - 10px)/2)`,
                             left: `calc(5px + ${text === 3 ? 0 : text === 4 ? 1 : text - 1}* calc((100% - 10px)/2))`
                         }}/>
            </div>
            <div className='descWrapper'>
                {info?.seriesDescription?.length > 0 ?
                    <div className='descContainer'>
                        <div className='title'>{info?.seriesName}</div>
                        <div className='content'>{info?.seriesDescription}</div>
                    </div>
                    :
                    info?.collections?.map((item, index) => {
                        return (
                            <div className='descContainer' style={{opacity: text === index + 1 ? '1' : '0'}}
                                 key={index}>
                                <div className='title'>{item.name}</div>
                                <div className='content'>{item.description}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const Card = (props)=> {
    const {info, index, currentIndex, setVideoVisible} = props;

    return(
        <div className='swiperCardContainer' onClick={ currentIndex - 1 === index && info?.displayURL?.length> 0  ?  ()=>{setVideoVisible(true)} : null}>
            {/*<img src={smallAndProgressiveImage(info?.thumbnailURL)} alt=""/>*/}
            <ProgressiveImage alt='' src={info?.thumbnailURL}/>
            {
                currentIndex - 1 === index && info?.displayURL?.length> 0  ?  <img src={PlayIcon} className='playButton' alt=""/>: null
            }
        </div>
    )
}

