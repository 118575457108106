import {AuthContext, serverAddress} from "../App";
import {useContext} from "react";
import {Toast} from "antd-mobile";

export default function useAnwser() {
  const { state } = useContext(AuthContext);
  const answer = async(id, question) => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        serverAddress + "/bot/" + id + "/ask?question=" + question + "&platform=web&profile=prd",
        { method: "GET", headers: headers }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return json.data;
      } else {
        Toast.show({
          content:json.msg,
          position:"bottom"
        })
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return answer;
}