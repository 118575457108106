import {useNavigate} from "react-router-dom";
import Cropper from "cropperjs";
import React, {useEffect, useState} from "react";
import {Toast} from 'antd-mobile';

import "cropperjs/dist/cropper.css";
import avatarLogo from './assets/nicknameLogo.svg';
import descLogo from './assets/descLogo.svg';

import "./Settings.css";
import back from "./assets/back.svg";
import CropperDialog from './components/cropperDialog/cropperDialog';
import useProfile from "../../utils/useProfile";
import userUpdateProfile from "../../utils/usePersonalInfo";
import userUpdateAvatar from "../../utils/useUpdateAvatar";
import Modal from "./components/modal/Modal";
import CollectionList from "./components/collectionsList/collectionList";
import HexagonAvatar from "../../components/hexagonAvatar/HexagonAvatar";
import {smallAndProgressiveImage} from "../../config";

export default function Settings() {
    const navigate = useNavigate();
    const getProfile = useProfile();
    const updateProfile = userUpdateProfile();
    const uploadAvatar = userUpdateAvatar();

    const [avatar, setAvatar] = useState('');
    const [username, setUsername] = useState('');
    const [intro, setIntro] = useState('');
    const [initUsername, setInitUsername] = useState('');
    const [initIntro, setInitIntro] = useState('');
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const [cropper, setCropper] = useState();
    const [nftListDialog, setNftListDialog] = useState(false);
    const [selectItem, setSelectItem] = useState(undefined);
    const [isNft, setIsNft] = useState();

    const backButton = () => {
        navigate(-1);
    };

    const getImage = (src) => {
        setOpen(true);
        setTimeout(() => {
            if(src){
                document.getElementById("cropImg").src= src;
            }else{
                const file = document.getElementById("uploadImg").files[0];
                document.getElementById("cropImg").src = window.URL.createObjectURL(file);
            }
            const image = document.getElementById("cropImg");

            image.onload = ()=>{
                let CROPPER = new Cropper(image, {
                    aspectRatio: 16 / 16,
                    viewMode: 3,
                    autoCropArea: 1,
                    dragMode: "move",
                });
                setCropper(CROPPER);
            }
        }, 500)
    };

    const chooseImage = () => {
        document.getElementById("uploadImg").click();
        if (cropper) {
            cropper.destroy();
        }
    };

    const uploadImage = () => {
        cropper.getCroppedCanvas({
            maxWidth: 4096,
            maxHeight: 4096,
            fillColor: "#fff",
            imageSmoothingEnabled: true,
            imageSmoothingQuality: "high",
        }).toBlob((blob) => {
            let img = new File([blob], 'avatar.png');
            let formData = new FormData();
            formData.append('file', img);
            if (document.getElementById("uploadImg")) {
                document.getElementById("uploadImg").value = '';
            }
            uploadAvatar(formData, selectItem).then(() => {
                getProfile(localStorage.getItem("userId")).then((res) => {
                    setAvatar(res.avatarURL);
                    setOpen(false);
                    setIsNft(res.is_nft);
                })
            }).then(() => {
                showToast();
            }).finally(()=>{
                setSelectItem(undefined);
            })
        });
    }

    const showToast = () => {
        Toast.show({
            content: '修改成功',
            position: 'bottom',
        })
    }

    const updateInfo = () => {
        if (initUsername !== username && initIntro !== intro) {
            updateProfile(username, intro).then(() => {
                showToast();
            });
        } else if (initUsername !== username && initIntro === intro) {
            updateProfile(username, undefined).then(() => {
                showToast();
            });
        } else if (initUsername === username && initIntro !== intro) {
            updateProfile(undefined, intro).then(() => {
                showToast();
            });
        } else {
            Toast.show({
                content: '暂无修改',
                position: 'bottom',
            })
        }
    }

    useEffect(() => {
        getProfile(localStorage.getItem("userId")).then((res) => {
            setUsername(res.userName);
            setIntro(res.introduction)
            setInitUsername(res.userName);
            setInitIntro(res.introduction);
            setAvatar(res.avatarURL);
            setIsNft(res.is_nft);
        })
    }, [])


  return (
    <div className="settingsContainer">
        <div className="avatarContainer" onClick={()=>{setModal(true)}}>
            <HexagonAvatar hexagon={isNft === 1} src={smallAndProgressiveImage(avatar)} icon={true}/>
        </div>

        <Modal setDialog={setModal} visible={modal} chooseImage={chooseImage} setNftListDialog={setNftListDialog}/>
        <CollectionList setDialog={setNftListDialog} visible={nftListDialog} getImage={getImage} selectItem={selectItem} setSelectItem={setSelectItem}/>
      <div className="backButton" onClick={() => backButton()}>
        <img src={back} alt="" />
      </div>


      <div>
        <input style={{ display: "none" }} type="file" accept="image/*" id="uploadImg" onChange={() => {getImage()}}/>
      </div>
        <div className={'nicknameContainer'}>
            <div className={'nickname'}>昵称</div>
            <div className={'nicknameInput'}>
                <img className={'logoContainer'} src={avatarLogo} alt={''}/>
                <input size="32" type='text' maxLength={10} placeholder="请输入昵称" value={username} onChange={(e)=>{
                    setUsername(e.target.value);
                }}/>
            </div>
        </div>
        <div className={'nicknameContainer'}>
            <div className={'nickname'}>一句话</div>
            <div className={'nicknameInput'}>
                <img className={'logoContainer'} src={descLogo} alt={''}/>
                <input size="32" type='text' maxLength={32}  placeholder="请输入个人签名" value={intro} onChange={(e)=>{
                    setIntro(e.target.value);
                }}/>
            </div>
        </div>
        <CropperDialog uploadImage={uploadImage} setOpen={setOpen} open={open}/>
     <div>
        <button className='confirm' onClick={()=>{updateInfo()}}>确认修改</button>
      </div>
    </div>
  );
}
