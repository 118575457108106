import './Modal.css';
import CloseIcon from '../../../paymentOrderPage/assets/closeIcon.png';
import {useEffect, useState} from "react";
import CountDown from "../../../../components/countDown/CountDown";
import {bottomToast, phoneReg, stopBodyScroll} from "../../../../utils/functionUtils";
import useSMS from "../../../../utils/useSMS";
import {unbindCard} from "../../api";

export default function Modal(props){
    const { visible, setDialog, setHasCard } = props;
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [code, setCode] = useState(undefined);
    const [getCode,setGetCode] = useState(false);

    const sendCode = useSMS(phoneNumber);

    const validateGetCode = () =>{
        if(phoneReg.test(phoneNumber)){
            setGetCode(true);
            sendCode().then((res)=>{
                if(res){
                    bottomToast('验证码已发送')
                }else{
                    bottomToast('发送失败')
                }
            })
        }else{
            bottomToast('请输入正确的手机号')
        }
    }

    const clickUnbind =()=>{
        unbindCard(phoneNumber, code).then((res)=>{
            if(res.data.returnCode === '20000'){
                setHasCard(res.data.data.hasBankCard);
                closeModal();
                bottomToast('解绑成功');
            }
        })
    }

    const closeModal =()=>{
        setPhoneNumber(undefined);
        setCode(undefined);
        setDialog(false);
    }

    useEffect(()=>{
        stopBodyScroll(visible);
    },[visible])

    return(
        <div className='unbindModalBackground' style={{ display: visible?'flex':'none'}}>
            <div className='modalContainer'>
                <div className='mainModal'>
                    <div className='modalTitle'>
                        确认解除绑定
                    </div>
                    <div className='modalContent'>
                        <div className='contentSubtitle'>手机号</div>
                        <input className='input' placeholder={'请输入登录账号的手机号'}  value={visible? phoneNumber: ''}
                               type="number" onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
                        <div className='contentSubtitle'>验证码</div>
                        <div style={{position: 'relative'}}>
                            <input className='input' placeholder={'请填写验证码'}
                                   value={visible? code: ''}
                                   onChange={(e)=>{setCode(e.target.value)}}/>
                            {getCode ?
                                <div className={'countdownCodeText'}>
                                    <CountDown seconds={'60'} type={'code'} setGetCode={setGetCode} getCode={getCode}/>
                                </div>
                                :
                                <div onClick={validateGetCode} className='codeText'>获取验证码</div>
                            }
                        </div>

                    </div>
                    <div className='modalFooter'>
                        <div className='leftFooter'>
                            <div className='leftFooterButtonContainer'>
                                <div className='buttonBox' onClick={closeModal}>取消</div>
                            </div>
                        </div>
                        <div className='rightFooter'>
                         <div className='rightFooterButtonContainer' onClick={clickUnbind}>确认</div>
                        </div>
                    </div>
                </div>
                <div className='modalLine'>
                    <img src={CloseIcon} className='closeIcon' onClick={closeModal} alt=""/>
                </div>
            </div>
        </div>
    )
}