import './AddNewCardPage.css';
import BackIcon from "../../../../assets/whiteBackButton.svg";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import CountDown from "../../../../components/countDown/CountDown";
import Checkbox from '@mui/material/Checkbox';
import useSMS from "../../../../utils/useSMS";
import {bindCard} from "../../api";
import {bottomToast} from "../../../../utils/functionUtils";

export default function AddNewCardPage(){
    const navigate = useNavigate();
    const [getCode, setGetCode] = useState(false);
    const [name, setName] = useState(undefined);
    const [cardNumber, setCardNumber] = useState(undefined);
    const [code, setCode] = useState(undefined);
    const [checked, setChecked] = useState(false);
    const [getCodeTime,setGetCodeTime] =useState(false);

    const phoneNumber = sessionStorage.getItem('phone');
    const sendCode = useSMS(phoneNumber);

    const goBack = () =>{ navigate(-1) }

    const validateGetCode = () =>{
            setGetCode(true);
            setGetCodeTime(true);
            sendCode().then((res)=>{
               if(res){
                   bottomToast('验证码已发送')
               }else{
                   bottomToast('发送失败')
               }
            })
    }

    const clickBindButton = () =>{
        bindCard(cardNumber,phoneNumber,name,code).then((res)=>{
            if(res.data.returnCode === '20000'){
                navigate(localStorage.getItem('orderUrl'));
                bottomToast('绑定成功')
            }else{
                bottomToast(res.data.msg);
            }
        })
    }

    useEffect(()=>{
        if(localStorage.getItem('authenticated') === '0'){
            bottomToast('请先实名认证');
            navigate('/');
        }
    },[])

    return(
        <div className='addNewCardPageContainer'>
            <img src={BackIcon} style={{ position: 'absolute', left: '20px', top: '20px',height: '17px'}} onClick={goBack} alt=""/>
            <div className='mainTitle'>添加银行储蓄卡</div>
            <div>
                <div className='subTitle'>请绑定与当前账号实名信息相同的银行卡号</div>
                <div className='subTitle' >本平台暂时只支持储蓄卡</div>
            </div>

                <div className='inputTitle'>持卡人</div>
            <div style={{position: 'relative'}}>
                <div className='input'>
                    <input placeholder={'请填写您本人的真实姓名'}
                           onChange={(e)=>{setName(e.target.value)}}/>
                </div>
                {/*<img src = {ProfileIcon} style={{height: '18px', position: 'absolute',top: '25px', left: '20px'}}/>*/}
            </div>

            <div className='inputTitle'>卡号</div>
            <input className='input' placeholder={'请输入本人银行卡卡号'}
                   onChange={(e)=>{setCardNumber(e.target.value)}}/>

            <div className='inputTitle'>手机号+86 {sessionStorage.getItem('phone').replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")
            }</div>
            <div style={{position: 'relative'}}>
                <input className='input' placeholder={'输入验证码'} type="number"
                       onChange={(e)=>{setCode(e.target.value)}}/>

                {getCode ?
                    <div className={'countdownCodeText'}>
                        <CountDown seconds={'60'} type={'code'} setGetCode={setGetCode} getCode={getCode}/>
                    </div>
                    :
                    <div onClick={validateGetCode} className='codeText'>获取验证码</div>
                }
            </div>

            {/*<div className='inputTitle'>验证码</div>*/}
            {/*<input className='input' placeholder={'请填写验证码'}*/}
            {/*       onChange={(e)=> {setCode(e.target.value)}}/>*/}

            <div className='dutyText'>
                <div>应法律要求，所有使用数字服务的用户银行绑定的手机号</div>
                <div>需和购买数字藏品账号的手机号一致</div>
            </div>

            <div className='checkLine'>
                <Checkbox  onChange={(e)=>{setChecked(e.target.checked)}}
                           sx={{ padding: "0", color: 'rgba(255, 255, 255, 0.5)', '& .MuiSvgIcon-root': { fontSize: "4.5333vw" }}}
                />
                <div className='agreementText'>我已阅读并接受</div>
                <div className='agreement'>《支付服务协议》</div>
            </div>


            <div className='confirmButton' style={{
                background: phoneNumber && code && name && cardNumber && checked && getCodeTime?
                        'linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%)':'#7C7C7C'}}
            onClick={clickBindButton}>
                确认添加
            </div>
        </div>
    )
}
