import {serverAddress} from "../App";
import {Toast} from "antd-mobile";

export default function UseCreateOrder() {

    const createOrder = async(releaseId, collectionId) => {
        let headers = new Headers();
        headers.append("accessToken", localStorage.getItem('accessToken'));
        try {
            const response = await fetch(
                serverAddress + '/payment/createOrder',
                { method: "Post",
                    headers: headers,
                   body: JSON.stringify({ releaseId: parseInt(releaseId), collectionId: parseInt(collectionId) })
                }
            );

            const json = await response.json();

            if (json.returnCode === "20000") {
                return json.data;
            } else {
                Toast.show({
                    content:json.msg,
                    position:"bottom"
                })
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return false;
        }
    }

    return createOrder;
}