import backButton from "../../assets/back.svg";
import {useNavigate} from "react-router";
import './BackButton.css';

export default function BackButton(props) {
    const {onClick, errorBoundary} = props;
    const navigate = useNavigate();

    const defaultBack = () => {
        if (onClick && errorBoundary) {
            navigate(-1);
            onClick();
        } else if (onClick) {
            onClick();
        } else {
            navigate(-1);
        }
}
return (
    <div>
        <img src={backButton} className='global-back-button' onClick={defaultBack} alt=""/>
    </div>
)
}