import {serverAddress} from "../App";
import {Toast} from "antd-mobile";

export default function useHomepageDetails() {
    const homepageDetails = async(releaseId) => {
        try {
            const response = await fetch(
                serverAddress + "/payment/collectionDetails/" + releaseId,
                { method: "GET" }
            );

            const json = await response.json();

            if (json.returnCode === "20000") {
                return json.data;
            } else {
                Toast.show({
                    content:json.msg,
                    position:"bottom"
                })
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return false;
        }
    }

    return homepageDetails;
}