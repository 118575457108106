import './commonDetails.css';
import {ProgressBar, Toast} from 'antd-mobile'
import {useEffect, useState} from "react";
import CopyIcon from './assets/copyIcon.png';
import BackIcon from '../../assets/whiteBackButton.svg';
import {useLocation, useNavigate,} from "react-router-dom";
import useHomepageDetails from "../../utils/useHomepageDetails";
import CountDown from "../../components/countDown/CountDown";
import dayjs from "dayjs";
import {getBankCardInfo} from "../bankCardManagePage/api";
import {smallAndProgressiveImage} from "../../config";

export default function CommonDetails(){
    const [height, setHeight] = useState();
    const [expand, setExpand] = useState(false);
    const [textOver, setTextOver] = useState(false);
    const [hasCard, setHasCard] = useState(true);
    const [nft, setNft] = useState();

    const navigate = useNavigate();
    const location = useLocation();
    const details = useHomepageDetails();

    const releaseId = location.pathname.replace('/commonDetails/','');
    localStorage.setItem('releaseId', releaseId);
    const diffSeconds = dayjs(nft?.saleAt).diff(dayjs(),'seconds');
    const isLogin = localStorage.getItem('accessToken');

    const toListPage=()=>{ navigate('/')}

    const toPaymentPage= () =>{
        if(!isLogin){
            navigate('/login');
        }else if(nft?.releaseId && nft?.collectionId){
            localStorage.setItem('releaseId', nft?.releaseId);
            localStorage.setItem('collectionId', nft?.collectionId);
            navigate(`/payment/undefined`);
        }
    }

    useEffect(()=>{
        const element = document.getElementById('contentDesc');
        setHeight(`${element.offsetHeight}px`);
        if(element.scrollHeight > element.offsetHeight){
            setTextOver(true);
        }

        details(releaseId).then((res)=>{
            setNft(res);
        })

        if(localStorage.getItem('accessToken')){
            getBankCardInfo().then((res)=>{
                if(res.data.returnCode === '20000'){
                    setHasCard(res.data.data.hasBankCard);
                }})
        }
    },[]);

    useEffect(()=>{
        const element = document.getElementById('contentDesc');
        setHeight(`${element.offsetHeight}px`);
    },[expand])

    return(
        <div className='commonDetailsContainer'>
            <div className='content'>
                <img src={BackIcon} style={{ position: 'absolute', height: '17px', margin: '20px', left: '0', zIndex: '4'}} onClick={toListPage} alt=""/>
                <div className='headerImg'
                     style={{ backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(14, 23, 42, 1)), url(${smallAndProgressiveImage(nft?.background)})`}}>
                </div>
                <div className='saleInfo'>
                    <div className='saleHeader'>
                        <div className='saleSingleContainer'>
                            <div className='saleTitle'>发售日期</div>
                            <div>{nft?.status === 'endOfSale'? '已结束': nft?.status === 'comingSoon'? nft?.saleAt: nft?.status === 'onSale'?
                                <CountDown hours={`${diffSeconds/3600}`} minutes={`${(diffSeconds%3600)/60}`} seconds={`${diffSeconds%60}`} type={'details'}/>: null}
                            </div>
                        </div>
                        <div className='saleSingleContainer'>
                            <div className='saleTitle'>发行数量</div>
                            <div>{nft?.totalNum}</div>
                        </div>
                    </div>
                    {nft?.status === 'onSale'?
                        <div style={{ width: '255px', margin: 'auto',position: 'relative', marginBottom: '15px'}}>
                        <ProgressBar percent={ nft?.amount/ nft?.totalNum * 100 } style={{
                            '--track-width': '16px',
                            '--fill-color': 'linear-gradient(90deg, #6548E8 0.01%, #8B7BEF 50%, #6BC7FA 99.99%)',
                            '--track-color': '#373C62'}}/>
                        <div className='progressBarText'>剩{nft?.amount}</div>
                    </div>: null}
                </div>
                <div className='contentTitle' style={{ marginTop:nft?.status === 'onSale'? '80px': '50px'}}>{nft?.name}</div>
                <div className='contentDesc' id='contentDesc' style={{'--height': height, maxHeight: expand? 'fit-content': '132px'}}>
                    {textOver? <button className='expand' onClick={()=>{setExpand(!expand)}}>{textOver?!expand?'...展开': '收起':null}</button>: null}
                    {nft?.description}
                </div>

                <div className='infoCard'>
                    <div className='InfoCardTitle'>藏品信息</div>
                    <div className='columnContainer'>
                        <div className='columnTitle'>发行方</div>
                        <div className='columnText'>{nft?.publisher}</div>
                    </div>
                    <div className='columnContainer'>
                        <div className='columnTitle'>创作者</div>
                        <div className='columnText'>{nft?.creator}</div>
                    </div>
                    <div className='columnContainer'>
                        <div className='columnTitle'>所在链</div>
                        <div className='columnText'>{nft?.blockchain}</div>
                    </div>
                    <div className='columnContainer'>
                        <div className='columnTitle'>合约地址</div>
                        <div className='columnImgContainer'>
                            <img src={CopyIcon} style={{height: '11px', marginRight: '4px'}} onClick={()=> copy(nft?.contractAddress)} alt= ''/>
                            <div className='columnText'>{getSubString(nft?.contractAddress)}</div>
                        </div>
                    </div>
                </div>
                <img src={nft?.background} style={{ width: '343px', margin: '0 auto 60px auto'}} alt= ''/>
            </div>
            {
                !isLogin?
                    <div className='alert'>
                        立即加入「星际岛」
                        <div className='alertButton'>
                            <div className='alertText' onClick={()=>{navigate('/login')}}>去注册</div>
                        </div>
                    </div>
                    :
                hasCard? null :
                <div className='alert'>
                    为避免抢购失败，请尽快添加支付信息
                    <div className='alertButton'>
                        <div className='alertText' onClick={()=>{navigate('/newCard')}}>去添加</div>
                    </div>
                </div>
            }

            <div className='footer'>
                <div className='priceContainer'>
                    <div className='priceTitle'>价格</div>
                    <div className='priceText'>¥ {nft?.price}</div>
                </div>
                {nft?.status === 'onSale' ?
                        <div className='paymentButton' onClick={toPaymentPage}>我想要</div>
                    : nft?.status === 'endOfSale' ?
                        <div className='saledButton'>已售罄</div> : nft?.status === 'comingSoon' ?
                    <div className='paymentButton' style={{ opacity: '0.5'}}>即将发售</div> : null
                }
            </div>
        </div>
    )
}

const copy = (content)=>{
    navigator.clipboard.writeText(content).then(() => {
        Toast.show({
            content: '复制成功',
            position: 'bottom',
        })
    });
}

const getSubString = (str) =>{
    if(str){
        let subStr1 = str.substr(0,10);
        let subStr2 = str.substr(str.length-5,5);
        return subStr1 + "..." + subStr2 ;
    }
}