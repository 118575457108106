import {useContext} from "react";
import {AuthContext, portalAddress} from "../App";
import {Toast} from "antd-mobile";

export default function useLogin() {
  const { dispatch } = useContext(AuthContext);

  const login = async(phone, code) => {
    try {
      const response = await fetch(
        portalAddress + "/user/login", 
        {
          method: "POST",
          body: JSON.stringify({ phone: phone, code: code }),
        }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        dispatch({
          type: "LOGIN",
          payload: {
            isLogin: true,
            userId: json.data.userId,
            accessToken: json.data.accessToken,
          },
        });
        window.collectEvent('config', {user_unique_id: json.data.userId});
        return true;
      } else {
        Toast.show({
          content:json.msg,
          position:"bottom"
        })
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return login;
}