import {AuthContext, serverAddress} from "../App";
import {useContext} from "react";
import {Toast} from "antd-mobile";

function useNFTDetail(type) {
  const { state } = useContext(AuthContext);

  if (type && type === "contract") {
    const nft = async(address, id) => {
      let headers = new Headers();
      headers.append("accessToken", state.accessToken);
      try {
        const response = await fetch(
          serverAddress + "/collectionAddress/" + address + "/nft/" + id,
          { method: "GET", headers: headers }
        );
        const json = await response.json();
        if (json.returnCode === "20000") {
          return json.data;
        } else {
          Toast.show({
            content:json.msg,
            position:"bottom"
          })
          return false;
        }
      } catch (error) {
        console.log("error", error);
        return false;
      }
    }
    return nft;
  } else {
    const nft = async(nftId) => {
      let headers = new Headers();
      headers.append("accessToken", state.accessToken);
      try {
        const response = await fetch(
          serverAddress + "/nft/" + nftId,
          { method: "GET", headers: headers }
        );
        const json = await response.json();
        if (json.returnCode === "20000") {
          return json.data;
        } else {
          Toast.show({
            content:json.msg,
            position:"bottom"
          })
          return false;
        }
      } catch (error) {
        console.log("error", error);
        return false;
      }
    }
    return nft;
  }
}

function useGetCardUrl() {
  const { state } = useContext(AuthContext);

  const getCardUrl = async(nftId) => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        serverAddress + "/card/" + nftId,
        { method: "GET", headers: headers }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return json.data;
      } else if (json.returnCode === "20011") {
        Toast.show({
          content: "内测中即将上线",
          position: "bottom"
        })
        return false;
      } else {
        Toast.show({
          content:json.msg,
          position:"bottom"
        })
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return getCardUrl;
}

function usePostCardUrl() {
  const { state } = useContext(AuthContext);

  const postCardUrl = async(nftId) => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        serverAddress + "/card/" + nftId,
        { method: "POST", headers: headers }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return json.data;
      } else {
        Toast.show({
          content:json.msg,
          position:"bottom"
        })
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return postCardUrl;
}

export { useNFTDetail, useGetCardUrl, usePostCardUrl }