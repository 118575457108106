import {useEffect, useState} from "react";
import {smallAndProgressiveImage} from "../../../config";
import "./ChatList.css";
import CopyIcon from '../assets/copy.svg';
import FolderIcon from '../assets/folder.svg';
import AiChatLoading from "./Loading";

export default function ChatList(props) {
    const {nft, chatHistory, chatBefore, chatNow, dailyGreeting, setCopyAlert} = props;
    const [firstChattingLoading, setFirstChattingLoading] = useState(true);
    const [dailyGreetingLoading,setDailyGreetingLoading] = useState(true);
    const [answerLoading,setAnswerLoading] = useState(true);

    useEffect(() => {
        updateScroll();
    }, [chatHistory?.length, chatNow?.length, dailyGreetingLoading,answerLoading]);

   useEffect(()=>{
       if(chatBefore?.hasChat){
           setFirstChattingLoading(false);
           setTimeout(()=>{
               setDailyGreetingLoading(false);
           },1000);
       }else if(!chatBefore?.hasChat){
           setTimeout(()=> {
               setFirstChattingLoading(false);
           },1000);
       }
   },[chatBefore])

   useEffect(() => {
        setAnswerLoading(true);
        setTimeout(()=>{
            setAnswerLoading(false);
        },1000);
   },[chatNow])

  return (
    <div className="chartListContainer" id="chatList">
        {chatBefore &&  (!firstChattingLoading ?<NormalAiConversation content={chatBefore?.firstChat} time={chatBefore?.time} nft={nft}/> : <LoadingConversation nft={nft}/>)}
        {chatHistory?.map((item,index)=>{
            return (
                <div key={`user${index}`}>
                    <UserConversation nft={nft} content={item}/>
                    <AiConversation nft={nft} content={item} setCopyAlert={setCopyAlert}/>
                 </div>
            )
        })}
        {chatBefore?.hasChat && dailyGreeting && (dailyGreetingLoading ? <LoadingConversation nft={nft}/> :
            <NormalAiConversation content={dailyGreeting?.greeting} time={dailyGreeting?.time} nft={nft}/>)}
        {chatNow?.map((item,index)=>{
            return (
                <div key={`user${index}`}>
                    <UserConversation nft={nft} content={item}/>
                    {index === chatNow.length - 1 && answerLoading ?  <LoadingConversation nft={nft}/> : <AiConversation nft={nft} content={item} setCopyAlert={setCopyAlert}/>}
                 </div>
            )
        })}
    </div>
  );
}

function AiConversation(props) {
  const { nft, content, setCopyAlert } = props;

  return (
    <div>
      <div className="aiConversationContainer">
          <div className='avatarContainer'>
              <img src={smallAndProgressiveImage(nft?.thumbnailURL)} alt="Avatar" className="chartListAvatar" />
          </div>
          {getAiAnswer(content,setCopyAlert)}
      </div>
      <div className="dateContainer">
          {content.visitTime.replace('-','年').replace('-','月').replace(' ','日 ')}
      </div>
    </div>
  );
}

function NormalAiConversation(props){
    const { nft, content, time } = props
    return (
        <div>
            <div className="aiConversationContainer">
                <div className='avatarContainer'>
                    <img src={smallAndProgressiveImage(nft?.thumbnailURL)} alt="Avatar" className="chartListAvatar" />
                </div>
                <div className="aiConversation">
                    <div className="aiConversationText">
                        {content}
                    </div>
                </div>
            </div>
            <div className="dateContainer">
                {time}
            </div>
        </div>
    );
}

function LoadingConversation(props){
    const { nft } = props;
    return (
        <div>
            <div className="aiConversationContainer">
                <div className='avatarContainer'>
                    <img src={smallAndProgressiveImage(nft?.thumbnailURL)} alt="Avatar" className="chartListAvatar" />
                </div>
                <div className="aiConversation">
                    <div className="aiConversationText">
                      <AiChatLoading/>
                    </div>
                </div>
            </div>
            <div className="dateContainer">
                {null}
            </div>
        </div>
    );
}

function UserConversation(props) {
  const { nft,content } = props;

  return (
    <div>
      <div className="userConversationContainer">
        <div className="userConversation">
          <div className="userConversationText">
              {content.question}
          </div>
        </div>
          <div className='avatarContainer'>
              <img src={nft?.ownerUserInfo.avatarURL} alt="Avatar" className="chartListAvatar" />
          </div>
      </div>
      <div className="userDateContainer">
          {content.visitTime.replace('-','年').replace('-','月').replace(' ','日 ')}
      </div>
    </div>
  );
}

function getAiAnswer(content,setCopyAlert) {
    const getSubString = (str) =>{
        if(str.length > 26){
            let subStr1 = str.substring(0,10);
            let subStr2 = str.substring(str.length-10,str.length);
            return subStr1 + '...' + subStr2;
        }
        return str;
    }

    const copy = (copyAddress)=>{
        navigator.clipboard.writeText(copyAddress).then(() => {
            setCopyAlert(true);
        });
    }

    switch (content?.answerType) {
        case "plaintext":
            switch (content?.type) {
                case 8:
                case 11:
                case 2 :  return (
                    <div className="aiConversation">
                        {content?.textContent?.image &&
                            <div className='imgContainer'>
                                <img src={content?.textContent?.image.substring(0,content?.textContent?.image.indexOf(","))} referrerPolicy="no-referrer" className="aiImg" alt=""/>
                            </div>
                           }
                        <div className="aiConversationText">
                            {content?.textContent?.content}
                        </div>
                        {content?.textContent?.url &&
                            <>
                                <div className="aiLink">{content?.textContent?.url}</div>
                                <div className="dividerLine"/>
                                <div className="copyLink">
                                <a href={content?.textContent?.url} className='link'>{getSubString(content?.textContent?.url)}</a>
                                <img src={CopyIcon} onClick={()=>copy(content?.textContent?.url)} alt=""/>
                                </div>
                            </>
                      }
                    </div>
                )
                case 0:
                    return(
                        <div className="aiConversation">
                            <div className="aiConversationText">
                                {content?.textContent?.content}
                            </div>
                        </div>
                    )
                default:
                    return null;
            }

        case "multimediaAnswers":
            for (let i = 0; i < content?.multimediaContent?.length ; i++) {
                switch (content?.multimediaContent[i].contentType) {
                    case 'image':
                        return (
                            <div className="aiConversation">
                                <div className='imgContainer'>
                                    {content?.multimediaContent[i]?.url && <img src={content?.multimediaContent[i]?.url} referrerPolicy="no-referrer" className="aiSoloImg" alt=""/> }
                                </div>
                            </div>
                        )
                    case 'file':
                        return (
                            <div className="aiConversation" style={{flexDirection: 'row'}}>
                                <img src={FolderIcon} style={{marginLeft: '12px'}} alt=""/>
                                <a className="aiConversationText" href={content?.multimediaContent[i].link}>
                                    {content?.multimediaContent[i].name}
                                </a>
                            </div>
                        )
                    case 'news':
                        return (
                            <div className="aiConversation">
                                {content?.multimediaContent[i].cover && <img src={content?.multimediaContent[i].cover} referrerPolicy="no-referrer" className="aiImg" alt=""/> }
                                <div className="aiConversationText">
                                    {content?.multimediaContent[i].content}
                                </div>
                                    <>
                                        <div className="aiLink">{content?.multimediaContent[i].link}</div>
                                        <div className="dividerLine"/>
                                        <div className="copyLink">
                                            <a href={content?.multimediaContent[i]?.link} className='link'>{getSubString(content?.multimediaContent[i]?.link)}</a>
                                            <img src={CopyIcon} onClick={()=>copy(content?.multimediaContent[i]?.link)} alt=""/>
                                        </div>
                                    </>
                            </div>
                        )

                    default:
                        return null;
                }
            }

        default:
            return null;
    }
}

const updateScroll = () => {
  let element = document.getElementById("chatList");
  if(element.scrollHeight > element.clientHeight){
      setTimeout(()=>{
          element.scrollTop = element.scrollHeight;
      },200)
  }
};


