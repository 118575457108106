import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import useProfile from "../utils/useProfile";
import useNFTList from "../utils/useNFTList";
import "./User.css";

import shareButton from '../assets/user/share.svg';
import backButton from "../assets/back.svg";
import noContentImg from "../assets/no-content.png";
import InfiniteScroll from "react-infinite-scroll-component";

import blackBiscuit from "../assets/biscuit.png";
import whiteBiscuit from "../assets/biscuit-white.png";
import ShareDialog from "../components/shareDialog";
import HexagonAvatar from "../components/hexagonAvatar/HexagonAvatar";
import {smallAndProgressiveImage} from "../config";

export default function User() {
  const [profile, setProfile] = useState([]);
  const [NFTList, setNFTList] = useState([]);
  const [dialogList, setDialogList] = useState([]);
  const [shareDialog, setShareDialog] = useState(false);

  const navigate = useNavigate();
  const getProfile = useProfile();
  const getNFTList = useNFTList();

  const params = useParams();
  const userId = params.id;

  const getSubStr = (str) => {
    if (str.length > 9) {
      var subStr1 = str.substr(0, 9);  
      str = subStr1 + "...";
    }
    return str;
  };

  const getDialogList = async() => {
    return await getNFTList(userId, 3, 1);
  }

  useEffect(async() => {
    const profile = await getProfile(userId);
    getDialogList().then((res) => {
      setDialogList(res.nfts)
    } );
    setProfile(profile);
    fetchData();
  },[params])

  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = async() => {
    const data = await getNFTList(userId, 7, pageNum);
    setNFTList(NFTList.concat(data.nfts));
    if (pageNum > Math.floor(data.totalNum/7)) {
      setHasMore(false);
    } else {
      setPageNum(pageNum + 1);
    }
  }

  return (
    <div className="user">
      <div className="menu-bar">
        <img className="back-button" src={backButton} onClick={()=>{navigate(-1)}} alt=''/>
        <div className="menu-title">星际岛</div>
        <div className="flex flex-row">
        <img className="share-button" src={shareButton} onClick={()=>setShareDialog(true)} alt=''/>
        </div>
      </div>
      <div className="main">
        <div className="profile">
          <HexagonAvatar hexagon={profile?.is_nft === 1} scale={0.8} src={profile?.avatarURL}/>
          <div className="profile-username">ID：{profile.userName}</div>
          <div className="profile-introduction">{profile.introduction === "" ? "这个人很懒，什么都没有留下" : profile.introduction}</div>
        </div>
        <div className="list">
          <div className="list-label">
            <div className="title">
              <svg width="3" height="18" viewBox="0 0 3 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0C1.65685 0 3 1.34315 3 3V15C3 16.6569 1.65685 18 0 18V0Z" fill="#0066FF"/>
              </svg>
              <p>TA的数字藏品</p>
            </div>
          </div>
          {NFTList.length > 0 ? (
            <InfiniteScroll dataLength={NFTList.length} next={fetchData} hasMore={hasMore} 
              loader={
                <p style={{ textAlign: 'center' }}>
                  <b>加载中...</b>
                </p>
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b/>
                </p>
              }
            >
              <div className="card-grid">
                {NFTList.map((nft, index) =>
                  <Link to={"/nft/" + nft.id} key={index} className="card-container">
                      {nft.collectionId === 5 ?
                        <div className="nft-card">
                          <img className="nfo-image" src={nft.thumbnailURL} alt=''/>
                          {nft.type === "white" ?
                            <img className="biscuit" src={whiteBiscuit} alt=''/>:
                            <img className="biscuit" src={blackBiscuit} alt=''/>
                          }
                        </div>:
                        <div className="nft-card">
                          <img className="nft-image" src={smallAndProgressiveImage(nft.thumbnailURL)} alt=''/>
                        </div>
                      }
                    <div className="card-title">
                      <div className="name">{getSubStr(nft.name)}</div>
                      <div className="card-info">
                        <div className="avatar-name">
                          <img src={profile.avatarURL} alt=''/>
                          <p>{profile.userName}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </InfiniteScroll>
          ):(
            <div className="no-content">
              <img src={noContentImg} alt=""/>
              <div className="des">暂无您的NFT数据</div>
            </div>
          )}
        </div>
      </div>
      <ShareDialog shareDialog={shareDialog} setShareDialog={setShareDialog} dialogList={dialogList} userId={userId} profile={profile}/>
    </div>
  )
}