import {useState} from "react";
import useClaim from "../../utils/useClaim";
import {Toast} from "antd-mobile";
import "./Claim.css";

import loading from "../../assets/loading.gif";
import success from "../../assets/claim-success.svg";

export default function Claim() {
  const [giftCode, setGiftCode] = useState();
  const [claimState, setClaimState] = useState();
  const [claimDisabled, setClaimDisabled] = useState(true);
  const post = useClaim();
  const getNFT = () => {
    setClaimState("loading");
    post(giftCode).then((res) => {
        setTimeout(() => {
          if (res !== "success") {
            if (res === "invalid") {
              Toast.show({
                content:"兑换码无效，请重新输入",
                position:"bottom"
              })
            } else if (res === "used") {
              Toast.show({
                content:"兑换码已使用，请重新输入",
                position:"bottom"
              })
            } else {
              Toast.show({
                content: res,
                position:"bottom"
              })
            }
            setClaimState("claim");
          } else {
            setClaimState("success");
          }
        }, 9000)
    })
  }
  const codeOnchange = (e) => {
    setGiftCode(e.target.value);
    console.log(e.target.value.length)
    if (e.target.value.length > 7) {
      setClaimDisabled(false);
    } else {
      setClaimDisabled(true);
    }
  }

  switch (claimState) {
    case "loading":
      return(
        <div className="claim loading">
          <div className="claim-container">
            <img src={loading} style={{borderRadius: "100px"}} alt=""/>
            <div className="text">努力铸造中...</div>
          </div>
        </div>
      )
    case "success":
      return(
        <div className="success">
          <img src={success} alt=""/>
          <div className="success-container">
            <div className="text">恭喜您，兑换成功！</div>
          </div>
        </div>
      )
    default:
      return (
        <div className="claim">
          <div className="claim-container">
            <div className="title">
              兑换
            </div>
            <div className="divider" />
            <div className="content">
              <input onChange={codeOnchange} />
            </div>
            <div className="control">
              <button disabled={claimDisabled} className="confirm" onClick={(getNFT)}>兑换</button>
            </div>
          </div>
        </div>
      )
  }
}