import React from "react";
import "./ErrorBoundaryPage.css";
import BackButton from "../../components/backButton/BackButton";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true};
  }

  handleClick(){
    this.setState({hasError: false});
  }

  componentDidCatch(error, errorInfo) {
    // 你同样可以将错误日志上报给服务器
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="errorBoundaryContainer">
          <BackButton onClick={this.handleClick} errorBoundary={true}/>
          <div>页面出现错误</div>
          <div>请刷新页面</div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
