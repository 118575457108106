import './Modal.css';
import CloseIcon from '../../assets/closeIcon.png';
import SuccessIcon from '../../assets/succs.png';
import {useEffect} from "react";
import {useNavigate} from "react-router";
import {stopBodyScroll} from "../../../../utils/functionUtils";

export default function Modal(props){
    const navigate = useNavigate();
    const { visible, setDialog, info } = props;

    const toListPage = () =>{
        navigate('/paymentOrderList');
    }

    const toDetailsPage = () =>{
        stopBodyScroll(false);
        navigate(`/nft/${info.nftId}`)
    }
    
    useEffect(()=>{
        stopBodyScroll(visible);
    },[visible])

    return(
        <div className='modalBackground' style={{ display: visible?'flex':'none'}}>
            <div className='modalContainer'>
                <div className='mainModal'>
                    <div className='modalTitle'>支付成功</div>
                    <div className='modalContent'>
                        <img src = {SuccessIcon} style={{ height: '74px'}} alt=""/>
                    </div>
                    <div className='modalFooter'>
                        <div className='leftFooter' onClick={toListPage}>查看订单</div>
                        <div className='rightFooter' onClick={toDetailsPage}>查看藏品</div>
                    </div>
                </div>
                <div className='modalLine'>
                    <img src={CloseIcon} className='closeIcon' onClick={()=>{setDialog(false)}} alt=""/>
                </div>
            </div>
        </div>
    )
}