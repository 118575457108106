import {serverAddress} from "../App";
import {Toast} from "antd-mobile";

export default function useCancelOrder() {
    const accessToken = localStorage.getItem('accessToken');

    const cancelOrder = async(orderId) => {
        let headers = new Headers();
        headers.append("accessToken", accessToken);
        try {
            const response = await fetch(
                serverAddress + `/payment/cancelOrder/${orderId}`,
                {
                    method: "POST",
                    headers: headers,
                }
            );
            const json = await response.json();
            if (json.returnCode === "20000") {
                return json.data;
            } else {
                Toast.show({
                    content:json.msg,
                    position:"bottom"
                })
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return "fail";
        }
    }

    return cancelOrder;
}