import './PaymentOrderListPage.css';
import BackIcon from "../../../../assets/whiteBackButton.svg";
import {useNavigate} from "react-router";
import HelpIcon from '../../assets/help.png';
import {useContext, useEffect, useState} from "react";
import Modal from "./components/modal/Modal";
import useOrderList from "../../../../utils/useOrderList";
import InfiniteScroll from "react-infinite-scroll-component";
import {AuthContext} from "../../../../App";
import {bottomToast} from "../../../../utils/functionUtils";
import {smallAndProgressiveImage} from "../../../../config";

export default function PaymentOrderListPage(){
    const navigate = useNavigate();
    const { state } = useContext(AuthContext);
    const [list, setList] = useState([]);
    const [dialog, setDialog] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const getList = useOrderList();

    const getOrderList = async ()=>{
        return await getList(8,1);
    }

    const fetchData = async() => {
        if(pageNum !== 1){
            const data = await getList(8,pageNum);
            setList(list.concat(data?.order));
            if (pageNum > Math.floor(data.totalNum/8)) {
                setHasMore(false);
            } else {
                setPageNum(pageNum + 1);
            }
        }
    }

    const goBack = () => { navigate('/') }

    useEffect(()=>{
        if(state?.accessToken){
            getOrderList().then((res)=>{
                setList(res?.order)
                if (pageNum > Math.floor(res.totalNum/8)) {
                    setHasMore(false);
                } else {
                    setPageNum(pageNum + 1);
                }
            })
        }
    },[state.accessToken])

    return(
        <div className='paymentOrderListContainer'>
            <Modal visible={dialog} setDialog={setDialog}/>
            <div className='header'>
                <img src={BackIcon} style={{ height: '17px'}} onClick={goBack} alt=""/>
                <div className='myOrderListText'>我的订单</div>
                <img src={HelpIcon} style={{ height: '18px'}} onClick={()=>{setDialog(true)}} alt=""/>
            </div>
            <InfiniteScroll dataLength={list?.length} next={fetchData} hasMore={hasMore}
                            loader={<p style={{ textAlign: 'center',color: "white" }}><b>加载中...</b></p>}
                            endMessage={list?.length>0 ? null:
                                <p style={{ textAlign: 'center' }}>
                                    <b>暂无订单</b>
                                </p>}>
            <div>
                {
                    list.map((item, index)=>{
                        return(<Card info={item} key={index}/>)})
                }
            </div>
            </InfiniteScroll>
        </div>
    )
}

const Card=(props)=>{
    const {info} = props;
    const navigate = useNavigate();

    const toPayment = ()=>{
        if(info.orderStatus === 'orderNotPayed'){
            navigate(`/payment/${info?.orderNumber}`,{replace: false,state:{ orderNumber: info?.orderNumber }})
        }else{
            bottomToast(`该订单${StatusText(info?.orderStatus)}`);
        }
    }

    return(
        <div className='paymentOrderCardContainer' onClick={toPayment}>
            <div className='cardContent'>
                <div className='orderNumber'>订单号：{info?.orderNumber}</div>
                <div className='line'/>
                <div className='mainContent'>
                    <div className='imgContainer'>
                        <img src={smallAndProgressiveImage(info?.releaseImage)} style={{ width: '100%', borderRadius:'6px' }} alt=""/>
                    </div>
                    <div className='rightContent'>
                        <div className='rightContentMainTitle'>{info?.typeName}</div>
                        <div className='bottomContainer'>
                            <div className='priceText'>¥ {info?.price}</div>
                            <StatusContent status={info?.orderStatus}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StatusContent =(props) =>{
    const {status} = props;

    switch (status) {
        case 'orderCompleted':
            return <div className='paymentSuccess'>支付成功</div>
        case 'orderCanceled':
            return <div style={{ color: 'red'}}>已取消</div>
        case 'orderNotPayed':
            return <div style={{ color: 'red'}}>待付款</div>
        case 'orderClosed':
            return <div style={{ color: 'red'}}>已关闭</div>
        case 'orderRefunded':
            return <div>已退款</div>
        case 'orderRefunding':
            return <div>退款中</div>
        case 'orderOverTime':
            return <div>已超时</div>
        case 'orderFailed':
            return <div>支付失败</div>
        default:
            return null;
    }
}

const StatusText = (status) =>{
    switch (status) {
        case 'orderCompleted':
            return '支付成功';
        case 'orderCanceled':
            return '已取消';
        case 'orderNotPayed':
            return '待付款';
        case 'orderRefunded':
            return '已退款';
        case 'orderRefunding':
            return '退款中';
        case 'orderOverTime':
            return '已超时';
        case 'orderFailed':
            return '支付失败';
        case 'orderClosed':
            return '已关闭';
        default:
            return null;
    }
}