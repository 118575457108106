import {AuthContext, portalAddress} from "../App";
import {useContext} from "react";
import {Toast} from "antd-mobile";

function usePostAuthentication() {
  const { state } = useContext(AuthContext);
  const alert = (content) => {
    Toast.show({
      content: content,
      position: "bottom"
    })
  }
  const post = async(code, name, idCard) => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        portalAddress + "/user/authentication",
        { method: "POST", headers: headers, body: JSON.stringify({ code: code, name: name, IDCard: idCard }), }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return json.data;
      } else {
        switch (json.returnCode) {
          case "23003":
            alert("身份证手机号不匹配！");
            break;
          case "23004":
            alert("手机号码无效！");
            break;
          case "30000":
            alert("身份证号码有误！");
            break;
          case "23006":
            alert("没有记录！");
            break;
          case "23010":
            alert("验证码错误！");
            break;
          default:
            alert("认证失败！");
            break;
        }
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }
  return post;
}

function useGetAuthentication() {
  const { state } = useContext(AuthContext);
  const get = async() => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        portalAddress + "/user/authentication",
        { method: "GET", headers: headers }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return json.data;
      } else {
        Toast.show({
          content:json.msg,
          position:"bottom"
        })
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }
  return get;
}

export {usePostAuthentication, useGetAuthentication};