import * as React from 'react';
import {Dialog, Select} from "@mui/material";
import {styled} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Checkbox from '@mui/material/Checkbox';
import useImport from "../../../utils/useImport";
import "./ImportDialog.css";
import closeIcon from "../../../assets/close-icon.svg";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from "@mui/material/MenuItem";
import {UbanquanImport} from "../api";
import {bottomToast} from "../../../utils/functionUtils";

const BootstrapInput = styled(InputBase)(({theme}) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#3B4066",
        border: 'none',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
        color: "white",
        fontWeight: "400",
        paddingLeft: "17px"
    },
}));

export default function ImportDialog(params) {
    const {setImportDialog, importDialog, handleImportClose} = params;
    const [address, setAddress] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [platform, setPlatform] = React.useState('红洞数藏');
    const [publisher, setPublisher] = React.useState('咖啡科技');

    const confirmImport = useImport();

    const addressOnChange = (e) => { setAddress(e.target.value) }
    const handleChange = () => { setChecked(!checked) }
    const handlePlatformChange = (e) => { setPlatform(e.target.value) }
    const handlePublisherChange = (e) => { setPublisher(e.target.value) }

    const handleConfirm = async () => {
        const res = await confirmImport(address);
        if (res) {
            bottomToast('导入成功');
        }
        setImportDialog(false);
        handleImportClose();
    }

    const upbanquanClick = () =>{
        UbanquanImport().then((res)=>{
            if(res.data.returnCode === '20000'){
                bottomToast('导入成功');
            }else{
                bottomToast(res.data.msg);
            }
            setImportDialog(false);
            handleImportClose();
        })
    }

    const getClickEvent =()=>{
        switch (platform) {
            case '红洞数藏':
                return handleConfirm;
            case 'AnyWeb':
                return null;
            case 'ubanquan':
                return upbanquanClick;
            default:
                return handleConfirm;
        }
    }

    const buttonDisable = ()=>{
        return platform === 'ubanquan'? !checked: !checked || address.length<=0;
    }

    React.useEffect(() => {
        setChecked(false);
        if(!importDialog){
            setPlatform('红洞数藏');
            setPublisher('咖啡科技');
        }
    }, [importDialog])

    return (
        <Dialog className='import-dialog' open={importDialog} sx={{
            "& .MuiDialog-paper": {
                minWidth: "343px",
                background: "#282C49",
                borderRadius: "6px",
                overflow: "visible"
            }
        }}>
            <div className="import">
                <div className="input-label">选择平台</div>
                <Select
                    style={{
                        background: "#3B4066",
                        border: "none",
                        width: "100%",
                        height: '45px',
                        borderRadius: '6px',
                        marginBottom: '24px',
                        fontSize: '16px',
                        color: 'rgba(255, 255, 255)',
                        paddingRight: '8px'
                    }}
                    onChange={handlePlatformChange}
                    value={platform}
                    IconComponent={() => (<KeyboardArrowDownIcon/>)}
                >
                    <MenuItem value={'红洞数藏'}>红洞数藏</MenuItem>
                    <MenuItem value={'AnyWeb'}>AnyWeb</MenuItem>
                    <MenuItem value={'ubanquan'}>优版权</MenuItem>
                </Select>
                {/* <input style={{background: "#3B4066", border: "none"}} onChange={addressOnChange} value="红洞数藏" disabled/> */}
                <div className="input-label">发行方</div>
                <Select
                    style={{
                        background: "#3B4066",
                        border: "none",
                        width: "100%",
                        height: '45px',
                        borderRadius: '6px',
                        marginBottom: '24px',
                        fontSize: '16px',
                        color: 'rgba(255, 255, 255)',
                        paddingRight: '8px'
                    }}
                    onChange={handlePublisherChange}
                    value={publisher}
                    IconComponent={() => (
                        <KeyboardArrowDownIcon/>
                    )}
                    defaultValue={publisher}
                >
                    <MenuItem value={'全部'}>全部</MenuItem>
                    <MenuItem value={'咖啡科技'}>咖啡科技</MenuItem>
                </Select>
                {/* <input style={{background: "#3B4066", border: "none"}} onChange={addressOnChange} value="咖菲科技" disabled/> */}
                {  platform !== 'ubanquan'?
                    <>
                        <div className="input-label">{getTitle(platform)}</div>
                        <input onChange={addressOnChange} placeholder={`请输入你的${getTitle(platform)}`}/>
                    </>  : null
                }
                {/* <div className="input-label">合约地址 </div> <input placeholder="请输入藏品的合约地址" /> */}
                <div className="notice">
                    <Checkbox onChange={handleChange} sx={{
                        margin: "1px 8px 0 0",
                        padding: "0",
                        color: "rgba(255, 255, 255, 0.5)",
                        '& .MuiSvgIcon-root': {fontSize: "16px"}
                    }}/>
                    <span style={{fontSize: "12px"}}>我已授权星际岛获取该平台下的数字藏品信息</span>
                </div>
                <button disabled={buttonDisable()}
                        onClick={getClickEvent(platform)} className="confirm-auth">一键导入</button>
            </div>
            <div className="dialog-close">
                <div className="close-line"/>
                <img className="close-icon" src={closeIcon} onClick={handleImportClose} alt=""/>
            </div>
        </Dialog>
    )
}

const getTitle =(selected) =>{
    switch (selected) {
        case '红洞数藏':
            return 'did地址';
        case 'AnyWeb':
            return 'cfx地址';
        default:
            return '地址'
    }
}