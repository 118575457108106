import {useEffect, useState} from "react";
import ClockIcon from '../../pages/commonHomePage/assets/Clock.png';
import OnSaleIcon from '../../pages/commonHomePage/assets/onSaleIcon.png';
import './CountDown.css';
import Frame from './assets/frame.png';

export default function CountDown({ hours = '0', minutes = '0', seconds = '0', type, setGetCode, getCode }) {
    const [over, setOver] = useState(false);
    const [time, setTime] = useState({
        hours: parseInt(hours),
        minutes: parseInt(minutes),
        seconds: parseInt(seconds)
    });

    const tick = () => {
        if (over) return;
        if (time.hours === 0 && time.minutes === 0 && time.seconds === 0)
            setOver(true);
        else if (time.minutes === 0 && time.seconds === 0)
            setTime({
                hours: time.hours - 1,
                minutes: 59,
                seconds: 59
            });
        else if (time.seconds === 0)
            setTime({
                hours: time.hours,
                minutes: time.minutes - 1,
                seconds: 59
            });
        else
            setTime({
                hours: time.hours,
                minutes: time.minutes,
                seconds: time.seconds - 1
            });
    };


    useEffect(() => {
        let timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    useEffect(() => {
        if (getCode) {
            const countDown = setTimeout(() => {
                setGetCode(false);
            }, parseInt(seconds) * 1000);
            return () => clearInterval(countDown);
        }
    }, [getCode])

    switch (type) {
        case 'sale':
            return (
                <div className='countDownContainer'>
                    { !over?
                        <>
                            <img src={ClockIcon} alt='' style={{height: '13px', marginRight: '3px'}}/>
                            <div>
                                {`发售倒计时: ${time.hours
                                    .toString()
                                    .padStart(2, "0")}:${time.minutes
                                    .toString()
                                    .padStart(2, "0")}:${time.seconds.toString().padStart(2, "0")}`}
                            </div>
                        </>:
                        <>
                            <img src = {OnSaleIcon} alt='' style={{height: '13px'}}/>
                            发售中
                        </>
                    }
                </div>
            );
        case 'payment':
            return(
                <div className='countDownContainer'>
                    { !over?
                        <>
                            {/*<div className='paymentText'>*/}
                            {/*    {`倒计时: ${time.hours*/}
                            {/*        .toString()*/}
                            {/*        .padStart(2, "0")}:${time.minutes*/}
                            {/*        .toString()*/}
                            {/*        .padStart(2, "0")}:${time.seconds.toString().padStart(2, "0")}`}*/}
                            {/*</div>*/}
                            <div className='clockContainer'>
                                <img src={Frame} alt='' style={{height: '100%'}}/>
                                <div className='clockNumber'>{time.hours.toString().padStart(2, "0")}</div>
                            </div>
                            <div className='colon'>:</div>
                            <div className='clockContainer'>
                                <img src={Frame} alt='' style={{height: '100%'}}/>
                                <div className='clockNumber'>{time.minutes.toString().padStart(2, "0")}</div>
                            </div>
                            <div className='colon'>:</div>
                            <div className='clockContainer'>
                                <img src={Frame} alt='' style={{height: '100%'}}/>
                                <div className='clockNumber'>{time.seconds.toString().padStart(2, "0")}</div>
                            </div>
                        </> :
                        <div>
                            已超时
                        </div>
                    }
                </div>
            )

        case 'details':
            return(
                <>
                    { !over?`剩余
                    ${time.hours.toString().padStart(2, "0") === '00' ? '': time.hours.toString().padStart(2, "0") + 'h'}
                    ${time.minutes.toString().padStart(2, "0") === '00' ? '': time.minutes.toString().padStart(2, "0") + 'm'}
                    ${time.seconds.toString().padStart(2, "0")}s`: '发售中'}
                </>
            )

        case 'code':
            return(
                <div className='verifyCodeText'>
                    {`${time.seconds.toString().padStart(2, "0")}s`}
                </div>
            )

        default:
            return time;
    }


}
