import {useContext, useEffect, useState} from "react";
import {smallAndProgressiveImage} from "../config";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../App";
import useEventDetail from "../utils/useEventDetail";
import "./EventDetail.css";
import backButton from "../assets/back.svg";

export default function EventDetail() {
  const { state } = useContext(AuthContext);
  const params = useParams();
  const [eventDetail, setEventDetail] = useState([]);
  const getEventList = useEventDetail();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData () {
      const data = await getEventList(params.id);
      setEventDetail(data);
    }
    fetchData();
  },[params.id, state.isLogin])

  return (
    <div className="event-detail">
      <div className="header">
        <img className="back" src={backButton} alt="back" onClick={()=>{navigate(-1)}}/>
        <div className="menu-title">发售日历</div>
      </div>
      <div className="main">
        <div className="event-card">
          <div className="banner">
            <img src={smallAndProgressiveImage(eventDetail.imageURL)} alt="" />
          </div>
          <div className="text">
            <p className="publisher">{eventDetail.publisher}</p>
            <div className="name-num">
              <div className="name">{eventDetail.name}</div>
              <div className="number">限量{eventDetail.amount}</div>
            </div>
          </div>
        </div>
        <div className="headline">项目介绍</div>
        <div className="divider" />
        <div className="intro">{eventDetail.story}</div>
        <div className="type-count">
          <div>共{eventDetail.series?.length}个系列</div>
        </div>
        <div className="card-grid">
          {eventDetail.series && eventDetail.series.map((series, index) => 
            <Link to={"/collection/" + series.collectionId + "?type=" + series.name} className="series-card" key={index}>
              <NftCover url={series.displayURL} poster={series.thumbnailURL} />
              <div className="name-shadow"/>
              <div className="series-name">{series.name}</div>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

function NftCover({url, poster}) {
  const [displayType, setDisplayType] = useState();

  useEffect(() => {  
    async function getDisplayType () {
      const response = await fetch(url);
      const type = response.headers.get("Content-Type");
      const regex = /.*(?=\/)/;
      const ext = regex.exec(type)[0];
      setDisplayType(ext);
    }
    getDisplayType();
  },[url])

  return (
    <div>
      {
        displayType === "video" ? 
        <video className="nft-image" poster={smallAndProgressiveImage(poster)} autoPlay loop muted playsInline>
          <source src={url} />
        </video> : 
        <img src={smallAndProgressiveImage(url)} alt=""/>
      }
    </div>
  )
}