import backButton from "../../../assets/whiteBackButton.svg";
import './Header.css';

export default function Header(props){
  const {handleClose, dayCount, nft} = props;
   
  return(
    <div>
        <div className='aiChartHeaderImg'
             style={{ backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(14, 23, 42, 1)), url(${nft.thumbnailURL})`}}>
        </div>
      <img src={backButton} alt="back" onClick={handleClose} className='aiChartBackIcon'/>
      <div className="aiChartHeaderText">星际猫已陪伴您 <span className="aiChartHeaderDayCountText">{dayCount} </span>天</div>
      <div className="aiChartLine"/>
    </div>
  )
}