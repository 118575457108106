import {AuthContext, confluxAddress} from "../App";
import {smallAndProgressiveImage} from "../config";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {validateAddress, validateCode} from "../utils/validator";
import useCollectSMS from "../utils/useCollectSMS";
import useCollectView from "../utils/useCollectView";
import useCollectConfirm from "../utils/useCollectConfirm";
import "./Collect.css";
import backImage from "../assets/back.svg";

export default function Collect() {
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  const [code, setCode] = useState();
  const [codeValid, setCodeValid] = useState(true);
  const [address, setAddress] = useState();
  const [addressValid, setAddressValid] = useState(true);
  const [requestId, setRequestId] = useState();

  const [codeBtnDisabled, setCodeBtnDisabled] = useState(false);
  const [codeBtnText, setCodeBtnText] = useState("获取验证码");
  const [count, setCount] = useState(0);

  const [detail, setDetail] = useState();
  const [stage, setStage] = useState("form");

  const addressOnchanged = (e) => {
    setAddressValid(true);
    setAddress(e.target.value);
  }

  const codeOnchanged = (e) => {
    setCodeValid(true);
    setCode(e.target.value);
  };

  const params = useParams();
  const sms = useCollectSMS();

  const getCode = async () => {
    sms(parseInt(params.id)).then((res) => {
      if (res) {
        setCodeBtnDisabled(true);
        setCount(60);
        setRequestId(res);
      } else {
        setCodeBtnText("获取验证码");
        setCount(0);
        setCodeBtnDisabled(false);
      }
    });
  };

  const collectValidate = useCollectView()
  const next = async() => {
    if (validateAddress(address) && validateCode(code)) {
      collectValidate(requestId, code, address).then((res) => {
        if (res) {
          setDetail(res);
          setStage("review");
        }
      })
    }
    if (!validateAddress(address)) {
      setAddressValid(false);
    }
    if (!validateCode(code))  {
      setCodeValid(false);
    }
  }

  useEffect(() => {
    if (count > 1) {
      setCodeBtnText(count +"s");
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
    if (count === 1) {
      setCodeBtnText(count +"s");
      setTimeout(() => {
        setCodeBtnText("获取验证码");
        setCount(0);
        setCodeBtnDisabled(false);
      }, 1000);
    }
  }, [count]);
  
  return (
    <div className="collect">
      <div className="notice">
        <img className="go-back" src={backImage} onClick={()=>{navigate(-1)}} alt=""/>
        <div className="headline">提取</div>
        <div className="content">
          <p>注意：您正在通过 Conflux Tethys 网络提取NFT数字资产。</p>
          <p>提取地址说明：提取地址应该为 Conflux Tethys 网络地址，地址格式仅支持 "cfx" 开头的 Conflux base32 格式，请仔细核对地址无误，且为您的个人私人地址。</p>
          <p className={stage}>提取须知：一旦确认提取请求，咖菲科技即认为您的提取信息准确无误，提取后，您的数字资产不再受 coPortal 资产托管系统保护，请确保提取地址为您的区块链地址正确无误，且为个人钱包地址。</p>
        </div>
      </div>
      {stage === "form"&&
      <div>
        <div className="input-card">
          <div className="input-title">提取地址</div>
          <div className="input-wrapper">
            <input className="address" size="1" maxLength={confluxAddress && (confluxAddress === "TESTNET" ? "50" : "46")} onChange={addressOnchanged} placeholder="请输入钱包地址"/>
            <div className="input-error">{!addressValid&&"地址格式不正确"}</div>
          </div>
          <div className="input-title">验证码 {state.phone && ("(" + state.phone?.substr(0, 3) + "****" + state.phone?.substr(11-4,11) + ")")}</div>
          <div className="input-container">
            <div className="input-wrapper">
              <input className="code" type="tel" size="1" maxLength="6" onChange={codeOnchanged} placeholder="请输入验证码"/>
            </div>
            <button onClick={getCode} disabled={codeBtnDisabled} className="code">{codeBtnText}</button>
          </div>
          <div className="input-error">{!codeValid&&"验证码错误"}</div>
        </div>
        <div className="action-container">
          <button className="next" onClick={next}>下一步</button>
          <button className="cancel" onClick={()=>{navigate(-1)}}>取消</button>
        </div>
      </div>
      }
      {stage === "review"&&<ToConfirm info={detail} id={requestId}/>}
    </div>
  )
}

function ToConfirm({info, id}) {

  const navigate = useNavigate();
  const collect = useCollectConfirm();

  const confirm = async () => {
    collect(id).then(() => {
      navigate("/collect-result?requestId=" + id);
    })
  }

  const getSubStr = (str) => {
    if (str) {
      var subStr1 = str.substr(0, 16);
      var subStr2 = str.substr(str.length - 9, 9);
      var subStr = subStr1 + "..." + subStr2;
      return subStr;
    }
  };

  return (
    <div className="collect">
      <div className="input-card">
        <div className="address">
          <div className="input-title">提取地址</div>
          <table className="information-content">
            <tbody>
              <tr>
                <td className="table-label">发送方：</td>
                <td className="table-value">cocafe托管地址</td>
              </tr>
              <tr>
                <td className="table-label">接收方：</td>
                <td className="table-value">{getSubStr(info?.address)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="input-title">NFT信息</div>
        <div className="nft-image">
          <img src={smallAndProgressiveImage(info?.imageURL)} alt=""/>
        </div>
        <table className="information-content">
          <tbody>
            <tr>
              <td className="table-label">合约地址：</td>
              <td className="table-value">{getSubStr(info?.contractAddress)}</td>
            </tr>
            <tr>
              <td className="table-label">项目名：</td>
              <td className="table-value">{info?.name}</td>
            </tr>
            <tr>
              <td className="table-label">发行方：</td>
              <td className="table-value">{info?.publisher}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="action-container">
        <button className="next" onClick={confirm}>确认</button>
        <button className="cancel" onClick={()=>{navigate(-1)}}>取消</button>
      </div>
    </div>
  )
}