import axios from "axios";
import {serverAddress} from "../../App";

export async function getBankCardInfo() {
  axios.defaults.headers.common["accessToken"] = localStorage.getItem("accessToken");
  return axios.get(`${serverAddress}/bankCard/info`);
}

export async function bindCard(bankCard, bankPhone, cardholder, code) {
  axios.defaults.headers.common["accessToken"] = localStorage.getItem("accessToken");

  return axios.post(`${serverAddress}/bankCard/bind`, {
    bankCard: bankCard,
    bankPhone: bankPhone,
    cardholder: cardholder,
    code: code,
  });
}

export async function unbindCard(userPhone, code) {
  axios.defaults.headers.common["accessToken"] = localStorage.getItem("accessToken");
  return axios.post(`${serverAddress}/bankCard/unbind`, {
    userPhone: userPhone,
    code: code,
  });
}

export async function getOrderInfo(orderId) {
  axios.defaults.headers.common["accessToken"] = localStorage.getItem("accessToken");
  return axios.get(`${serverAddress}/payment/orderDetails/${orderId}`);
}
