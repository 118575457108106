import {Dialog} from '@mui/material';
import './AiChartPage.css';
import Header from './components/Header';
import InputFooter from './components/InputFooter';
import ChatList from './components/ChatList';
import CopiedIcon from './assets/copied.svg';
import {useEffect, useState} from "react";
import {GetChatHistory, GetDailyGreeting, GetDaysCount} from "./api";
import dayjs from "dayjs";
import {bottomToast} from "../../utils/functionUtils";

export default function AiChartPage(props) {
  const {aiDialog, setAiDialog, nftId, nft}  = props;
  const [daysCount, setDaysCount] = useState();
  const [chatBefore, setChatBefore] = useState();
  const [chatHistory, setChatHistory] = useState();
  const [chatNow, setChatNow] = useState([]);
  const [dailyGreeting, setDailyGreeting] = useState();
  const [copyAlert, setCopyAlert] = useState(false);

  const clearChat = () => {
    setChatHistory([]);
    setChatNow([]);
  }

  const handleClose = () => {
    setAiDialog(false);
    clearChat();
  }

    const CopyAlert =(props)=>{
      const {copyAlert, setCopyAlert} = props;

      useEffect(()=>{
          if(copyAlert){
              setTimeout(()=>{
                  setCopyAlert(false);
              },1000)
          }
      },[copyAlert])

        return(
            <div className='copyAlert' style={{display: copyAlert? 'flex': 'none'}}>
                <img src={CopiedIcon} alt=""/>
                <div>已复制</div>
            </div>
        )
    }

    const updateChat = (conversation) => {
      setChatNow(chatNow.concat(conversation))
    }

    useEffect(()=>{
      const time = dayjs().format('YYYY-MM-DD HH:mm:ss');
      const greetingTime = dayjs().format("YYYY[年]MM[月]DD[日] HH:mm:ss");

      if(aiDialog){
          GetDaysCount(nftId).then(res=>{
              if(res.data.returnCode === '20000'){
                  setDaysCount(res.data.data.days);
                  setChatBefore({
                      firstChat: "哼！我是故意让你发现的。我是星际喵，穿越元宇宙裂缝来到你们人类世界，而我的其他同类可还潜藏着观察着你们……" +
                          "你一定有很多好奇吧，尽管问我，毕竟我可是比你们智慧得多的生物。如果我喜欢，我还可以在你身边呆久一点。希望我的同类不要这么快找来，他们的个性我可说不准...",
                      time:  res.data.data.timeRecord ? res.data.data.timeRecord.replace('-', '年').replace('-', '月').replace(' ', '日 ') : greetingTime,
                      hasChat: res.data.data.hasUsed
                  });
              }else{
                  bottomToast(res.data.data.msg);
              }
          }).catch(e=>{
              console.log(e);
          });

          GetDailyGreeting(nftId).then(res => {
              if (res.data.returnCode === '20000') {
                  setDailyGreeting({greeting: res.data.data.dailyGreeting, time: greetingTime});
              }
          });

          GetChatHistory(nftId,time,8).then((res)=>{
              if(res.data.returnCode === '20000'){
                  setChatHistory(res.data.data);
              }else{
                 setChatHistory([]);
              }
          }).catch((e)=>{
              console.log(e);
          });
      }
  },[aiDialog])

    function reverseChatHistory(array){
      let newArray= [];
        for (let i = array.length - 1; i >= 0; i--) {
            newArray[newArray.length] = array[i];
        }
        return newArray;
    }

  return (
      <Dialog fullScreen open={aiDialog} onClose={handleClose}>
          <div className="aiChartContainer" id='aiPage'>
              <Header dayCount={daysCount} handleClose={handleClose} nft={nft}/>
              <ChatList nft={nft} chatHistory={chatHistory && reverseChatHistory(chatHistory)} chatBefore={chatBefore} chatNow={chatNow}
                        dailyGreeting={dailyGreeting} setCopyAlert={setCopyAlert} copyAlert={copyAlert}/>
              <InputFooter id={nftId} updateChat={updateChat}/>
              <CopyAlert setCopyAlert={setCopyAlert} copyAlert={copyAlert}/>
          </div>
      </Dialog>
  )
}

