import './Modal.css';
import CloseIcon from '../../assets/closeIcon.png';
import {useEffect} from "react";
import {stopBodyScroll} from "../../../../utils/functionUtils";
import HexagonAvatar from "../../../../components/hexagonAvatar/HexagonAvatar";

export default function Modal(props){
    const { visible, setDialog, chooseImage, setNftListDialog } = props;

   const selectCamera=()=>{
       setDialog(false);
       chooseImage();
   }

   const selectCollection =()=>{
       setDialog(false);
       setNftListDialog(true);
   }

    useEffect(()=>{
        stopBodyScroll(visible);
    },[visible])

    return(
        <div className='avatarModalBackground' style={{ display: visible?'flex':'none'}}>
            <div className='modalContainer'>
                <div className='mainModal'>
                    <div className='fullAvatarContainer'>
                        <HexagonAvatar hexagon={true} icon={true}/>
                        <HexagonAvatar onClick={selectCamera} hexagon={false} icon={true}/>
                    </div>
                    <div className='avatarModalButtonContainer'>
                        <div className='avatarModalButtonSingleContainer'>
                            <div className='avatarModalButton' onClick={selectCollection}>
                                选择藏品
                            </div>
                        </div>
                        <div className='avatarModalButtonSingleContainer'>
                            <div className='avatarModalButton' onClick={selectCamera}>
                                选择相册
                            </div>
                        </div>
                    </div>
                </div>
                <div className='modalLine'>
                    <img src={CloseIcon} className='closeIcon' onClick={()=>{setDialog(false)}} alt=''/>
                </div>
            </div>
        </div>
    )
}