import './BankCardManagePage.css';
import {useNavigate} from "react-router-dom";
import BackIcon from "../../assets/whiteBackButton.svg";
import AddCardIcon from './assets/addCard.png';
import BankCardIcon from '../../assets/bankCardIcon.png';
import RightArrowIcon from './assets/rightArrow.png';
import DotsIcon from './assets/dots.png';
import {useEffect, useState} from "react";
import Modal from "./components/modal/Modal";
import {getBankCardInfo} from "./api";
import {bottomToast} from "../../utils/functionUtils";

export default function BankCardManagePage(){
    const navigate = useNavigate();
    const [dialog ,setDialog] = useState(false);
    const [hasCard, setHasCard] = useState(false);
    const [cardNumber, setCardNumber] = useState(undefined);

    const goBack = () =>{
        navigate(-1);
    }

    const toAddNewCard = () =>{
        navigate('/newCard')
    }

    useEffect(()=>{
        if (localStorage.getItem('authenticated') === '0') {
            bottomToast('请先实名认证');
            navigate('/');
        }
        getBankCardInfo().then((res)=>{
            if(res.data.returnCode === '20000'){
                setHasCard(res?.data?.data?.hasBankCard);
                if(res?.data?.data?.hasBankCard){
                    setCardNumber(res?.data?.data?.bankCard);
                }
            }
        })
    },[])

    return(
        <div className='bankCardManagePageContainer'>
            <Modal visible={dialog} setDialog={setDialog} setHasCard={setHasCard}/>
            <img src={BackIcon} style={{ position: 'absolute', left: '20px', top: '20px',height: '17px'}} onClick={goBack} alt=''/>
            <div className='title'>银行卡</div>
            {
                hasCard?
                    <BankCard cardNumber={cardNumber} setDialog={setDialog}/>:
                    <img src={AddCardIcon} className='addCardIcon' onClick={toAddNewCard} alt=''/>
            }
        </div>
    )
}

const BankCard = (props) =>{
    const {cardNumber, setDialog} = props;

    return(
        <div className='bankCardContainer'>
            <div className='bankCardTitle'>
                <div className='leftTitle'>
                    <img src={BankCardIcon} className='bankCardImg' alt=''/>
                    <div className='bankCardText'>储蓄卡</div>
                </div>
               <div className='rightTitle' onClick={()=>{setDialog(true)}}>
                   <div className='rightTitleText'>解绑</div>
                   <img src={RightArrowIcon} className='arrowIcon' alt=''/>
               </div>
            </div>
            <div className='numberContainer'>
                <div>{cardNumber?.slice(0,4)}</div>
                <img src={DotsIcon} className='dotsContainer' alt=''/>
                <img src={DotsIcon} className='dotsContainer' alt=''/>
                <div>{cardNumber?.slice(cardNumber?.length-4,cardNumber?.length)}</div>
            </div>
        </div>
    )
}