import axios from 'axios';
import {serverAddress} from "../../App";

export async function GetDaysCount(nftId){
    const accessToken =  localStorage.getItem("accessToken");
    axios.defaults.headers.common['accessToken'] = accessToken;
    return axios.get(`${serverAddress}/bot/${nftId}/companion`);
}

export async function GetDailyGreeting(nftId){
    const accessToken =  localStorage.getItem("accessToken");
    axios.defaults.headers.common['accessToken'] = accessToken;
    return axios.get(`${serverAddress}/bot/${nftId}/greet`)
}

export async function GetChatHistory(nftId,endTime, limit){
    const accessToken =  localStorage.getItem("accessToken");
    axios.defaults.headers.common['accessToken'] = accessToken;
    return axios.get(encodeURI(`${serverAddress}/bot/${nftId}/messageLog?endTime=${endTime}&limit=${limit}`));
}

export async function askQuestion(nftId,question){
    const accessToken =  localStorage.getItem("accessToken");
    axios.defaults.headers.common['accessToken'] = accessToken;
    return axios.get(`${serverAddress}/bot/${nftId}/ask?question=${question}&platform=web&profile=prd`)
}
