import './Loading.css';

export default function AiChatLoading(){
    return(
        <div className="container">
            <div className="shape"/>
            <div className="shape"/>
            <div className="shape"/>
        </div>
    )
}