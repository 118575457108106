import {AuthContext} from "../App";
import {useContext, useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import useProfile from "../utils/useProfile";
import useNFTList from "../utils/useNFTList";
import "./HomeDesktop.css";

import SideBar from "../components/SideBar";
import InfiniteScroll from "react-infinite-scroll-component";

import avatarURL from "../assets/portrait.png";
import blackBiscuit from "../assets/biscuit.png";
import whiteBiscuit from "../assets/biscuit-white.png";

import Header from "../components/Header";

export default function HomeDesktop() {
  const { state } = useContext(AuthContext);
  const [profile, setProfile] = useState([]);
  const [NFTList, setNFTList] = useState([]);
  const [totalNum, setTotalNum] = useState([]);
  const sideBarRef = useRef();

  const navigate = useNavigate();
  const getProfile = useProfile();
  const getNFTList = useNFTList();

  const getSubStr = (str) => {
    if (str.length > 9) {
      var subStr1 = str.substr(0, 9);
      str = subStr1 + "...";
    }
    return str;
  };

  const cardTouch = (e) => {
    console.log(e.target)
  }

  useEffect(async() => {
    if (state.isLogin) {
      const profile = await getProfile(state.userId);
      setProfile(profile);
      fetchData();
    }
    if (state.isLogin === false) {
      navigate("/login");
    }
  },[state.isLogin])

  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = async() => {
    const data = await getNFTList(state.userId, 100, pageNum);
    setNFTList(NFTList.concat(data.nfts));
    setTotalNum(data.totalNum);
    if (pageNum > Math.floor(data.totalNum/100)) {
      setHasMore(false);
    } else {
      setPageNum(pageNum + 1);
    }
  }

  return (
    <div className="homeDesktop">
      <Header />
      {/* <div className="menu-bar">
      <img className="menu-button" src={menuButton} onClick={() => {sideBarRef.current.openSideBar()}}/>
      <div className="menu-title">星际岛</div>
      <img className="calendar-button" src={calendarButton} onClick={() => {navigate("/event")}}/>
      </div> */}
      <div className="main">
          <div className="profile">
            <img className="profile-avatar" src={avatarURL} alt=""/>
            <div className="profile-username">{profile.userName}</div>
            <div className="profile-introduction"> 这个人很懒，什么都没有留下 </div>
            {/* <div className="profile-introduction">{profile.introduction === "" ? "这个人很懒，什么都没有留下" : profile.introduction}</div> */}
          </div>

          {/* <div className="twoTit">
            <div className="follow">
              <div className="follow-up">456</div>
              <div className="follow-down">关注</div>
            </div>
            <div className="split-line">  </div>
            <div className="fan">
            <div className="fan-up">2.4K</div>
              <div className="fan-down">粉丝</div>
            </div>
          </div> */}

          <div className="list">
            <div className="list-label">
              <div className="title">
                
                <div style={{display: "flex",height: "32px"}} >
                <svg width="4" height="24" viewBox="0 0 3 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0C1.65685 0 3 1.34315 3 3V15C3 16.6569 1.65685 18 0 18V0Z" fill="#0066FF"/>
                </svg>
                <p>我的数字藏品</p>
                </div>

                {/* <div style={ { height: "32px", width: "32px",margin:"0 8px 0 0 "}}><img src={eyeico}/> </div> */}
              </div>
            </div>
            {NFTList.length > 0 ? (
            <InfiniteScroll dataLength={NFTList.length} next={fetchData} hasMore={hasMore} loader={null}>
              <div className="card-grid">
                {NFTList.map((nft, index) => 
                  <Link to={"/nft/" + nft.id} key={index} className="card-container" onMouseDown={cardTouch}>
                      {nft.collectionId === 5 ?
                        <div className="nft-card">
                          <img className="nfo-image" src={nft.thumbnailURL} alt=""/>
                          {nft.type === "white" ?
                            <img className="biscuit" src={whiteBiscuit} alt=""/>:
                            <img className="biscuit" src={blackBiscuit} alt=""/>
                          }
                        </div>:
                        <div className="nft-card">
                          <img className="nft-image" src={nft.thumbnailURL} alt=""/>
                        </div>
                      }
                    <div className="card-title">
                      <div className="name">{getSubStr(nft.name)}</div>
                      <div className="card-info">
                        <div className="avatar-name">
                          <img src={avatarURL} alt=""/>
                          <p>{profile.userName}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}

              </div>
              {/* <div> <img style={{ height: "50px", width: "50px", margin: "50px auto 13% auto", }} src={loading}/></div> */}

            </InfiniteScroll>
            ):(
              <div className="no-content">
                      {/* <img src={noContentImg} />
                      <div className="des">暂无您的NFT数据</div> */}

                {/* <div className="line">
                      <div className="box">
                          <img src={card1}/>
                          <div className="bottom">
                            <div className="title">  </div>
                            <div className="contain">
                              <div className="left" >
                                <img style={{ height: "16px", width: "16px", }} src={leftcorner} className="showpic"/> &nbsp; 
                               
                              </div>
                              <div className="right">  </div>
                            </div>
                          </div>
                      </div>
                     
                     
                     
                </div> */}

              </div>
              
              )}
        </div>
        <SideBar profile={profile} totalNum={totalNum} ref={sideBarRef}/>
      </div>
    </div>
  )
}