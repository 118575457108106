import {portalAddress} from "../App";
import {Toast} from "antd-mobile";

export default function useSMS(phone) {

  const sendSMS = async () => {
    try {
      const response = await fetch(
        portalAddress + "/user/sendSms", 
        {
          method: "POST",
          body: JSON.stringify({ phone: phone }),
        }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return true;
      } else {
        Toast.show({
          content:json.msg,
          position:"bottom"
        })
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  return sendSMS;
}