import React from "react";
import "./ProgressiveImage.css";
import {smallAndProgressiveImage} from "../../config";

const ProgressiveImage = (props) => {
    const {src, alt} = props;
    const [isLoaded, setIsLoaded] = React.useState(false);

    const isGif = src.includes('.gif');

    return (
        <React.Fragment>
            <img
                className="thumb"
                alt={alt}
                src={smallAndProgressiveImage(src, true)}
                style={{ display: isLoaded || isGif ? "none" : "block" }}
            />
            <img
                onLoad={() => {
                    setIsLoaded(true);
                }}
                className="full"
                style={{ display: isLoaded || isGif ? 'block' : 'none' }}
                alt={alt}
                src={smallAndProgressiveImage(src, false)}
            />
        </React.Fragment>
    );
};
export default ProgressiveImage;
