import {useNavigate} from "react-router";
import "./index.css";
import backButton from "../../assets/back.svg";

export default function ArPage() {
  const navigate = useNavigate();
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  return (
    <div class="ar-page">
      <div className="header">
        <img className="back" src={backButton} alt="back" onClick={()=>{navigate(-1)}}/>
      </div>
      <ArFrame url={searchParams.get("url")} />
    </div>
  )
}

function ArFrame(params) {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return (
    <div class="threedbook-embed-wrapper">
      <iframe title="ar" width={width} height={height} src={params.url} frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true">
      </iframe>
    </div>                           
  )
}