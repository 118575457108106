import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../App";
import {smallAndProgressiveImage} from "../config";
import InfiniteScroll from "react-infinite-scroll-component";
import useEventList from "../utils/useEventList";
import "./EventList.css";
import backButton from "../assets/back.svg";

export default function Events() {
  const { state } = useContext(AuthContext);
  const [eventList, setEventList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const getEventList = useEventList();
  const navigate = useNavigate();

  useEffect(async() => {
    if (state.isLogin) {
      fetchData();
    } 
    if (state.isLogin === false) {
      navigate("/login");
    }
  },[state.isLogin])

  const fetchData = async() => {
    const data = await getEventList(4, pageNum);
    setEventList(eventList.concat(data.collections));
    if (pageNum > Math.floor(data.totalNum/4)) {
      setHasMore(false);
    } else {
      setPageNum(pageNum + 1);
    }
  }

  const getSubStr = (str) => {
    if (str.length > 20) {
      var subStr = str.substr(0, 20) + "...";
      return subStr;
    }
    return str;
  };

  const toWalletPage =()=>{
    navigate(-1)
  }

  return (
    <div className="events">
      <div className="header">
        <img className="back" src={backButton} alt="back" onClick={toWalletPage}/>
        <div className="menu-title">发售日历</div>
      </div>
      <div className="main">
        <InfiniteScroll dataLength={eventList.length} next={fetchData} hasMore={hasMore} 
          loader={
            <p style={{ textAlign: 'center' }}>
              <b>加载中...</b>
            </p>
          }
          endMessage={
            <p style={{ textAlign: 'center', marginBottom: "20px" }}>
              <b/>
            </p>
          }
        >
          {eventList.map((event, index) => 
            <Link to={"/event/" + event.id} className="event-card" key={index}>
              <div className="banner">
                <img src={smallAndProgressiveImage(event.imageURL)} alt=""/>
              </div>
              <div className="text">
                <p className="publisher">{event.publisher}</p>
                <div className="name-num">
                  <div className="name">{getSubStr(event.name)}</div>
                  <div className="number">限量{event.amount}</div>
                </div>
              </div>
            </Link>
          )}
        </InfiniteScroll>
      </div>
    </div>
  )
}