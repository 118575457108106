import {createContext, useEffect, useReducer} from "react";
import {useNavigate} from "react-router";
import {Route, Routes, useLocation} from "react-router-dom";
import Home from "./pages/homePage/Home";
import {isMobile} from 'react-device-detect';
import Login from "../src/pages/Login";
import Detail from "./pages/detailsPage/Detail";
import Collect from "../src/pages/Collect";
import CollectResult from "../src/pages/CollectResult";
import Events from "./pages/EventList";
import EventDetail from "./pages/EventDetail";
import CollectionList from "./pages/CollectionList";
import HomeDesktop from "../src/pages/HomeDesktop";
import LoginDesktop from "../src/pages/LoginDesktop";
import DetailDesktop from "../src/pages/DetailDesktop";
import User from "../src/pages/User";
import Settings from "./pages/settingsPage/Settings";
import './App.css';
import Authentication from "./pages/Authentication/Authentication";
import CommonHomePage from "./pages/commonHomePage/CommonHomePage";
import CommonDetails from "./pages/commonDeatilsPage/commonDetails";
import PaymentOrderPage from "./pages/paymentOrderPage/PaymentOrderPage";
import BankCardManagePage from "./pages/bankCardManagePage/BankCardManagePage";
import AddNewCardPage from "./pages/bankCardManagePage/components/addNewCardPage/AddNewCardPage";
import PaymentOrderListPage from "./pages/paymentOrderPage/components/paymentOrderListPage/PaymentOrderListPage";
import SeriesDetails from "./pages/seriesDetails/SeriesDetails";
import LibraryPage from "./pages/LibraryPage";
import SeriesPage from "./pages/SeriesPage";
import ArPage from "./pages/ArPage";
import ErrorBoundary from './pages/errorPage/ErrorBoundaryPage';

const initialState = {
  isLogin: null,
  userId: null,
  accessToken: null, 
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("userId", action.payload.userId);
      localStorage.setItem("accessToken", action.payload.accessToken);
      sessionStorage.setItem("phone", action.payload.phone);
      return {
        ...state,
        isLogin: true,
        userId: action.payload.userId,
        avatarURL: action.payload.avatarURL,
        accessToken: action.payload.accessToken,
        phone: action.payload.phone,
        authenticated: action.payload.authenticated
      };
    case "LOGOUT":
      localStorage.removeItem("userId");
      localStorage.removeItem("accessToken");
      return {
        ...state,
        isLogin: false,
        userId: null,
        avatarURL: null,
        accessToken: null,
        authenticated: null
      };
    default:
      return;
  }
}

export const AuthContext = createContext();
export const portalAddress = process.env.REACT_APP_PORTAL_ADDRESS;
export const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
export const confluxAddress = process.env.REACT_APP_CONFLUX_ADDRESS;

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.scrollTo(0,0);
    async function fetchData(){
      if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
        let headers = new Headers();
        headers.append("accessToken", localStorage.getItem("accessToken"));
        try {
          const response = await fetch( portalAddress + "/user/token", { method: "GET", headers: headers, } );
          const json = await response.json();
          if (json.returnCode === "20000") {
            localStorage.setItem('authenticated',json?.data?.authenticated);
            dispatch({
              type: "LOGIN",
              payload: {
                isLogin: true,
                userId: localStorage.getItem("userId"),
                avatarURL: json.data.avatarURL,
                accessToken: localStorage.getItem("accessToken"),
                phone: json.data.phone,
                authenticated: json.data.authenticated
              }
            })
          } else {
              dispatch({
                type: "LOGOUT",
              })
            }
        } catch (error) {
          console.log("error", error);
        }
      } else if(location.pathname=== '/' || location.pathname.includes('/commonDetails/')) {

      } else {
        navigate("/login")
        dispatch({
          type: "LOGOUT",
        })
      }
    }
    fetchData();
  },[navigate])

  return (
    <div className="App m-auto">
      <AuthContext.Provider value = {{state, dispatch}}>
        <ErrorBoundary>
        <Routes>
          <Route path="/" element={<CommonHomePage/>}/>
          <Route path="/homepage" element={<Home/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/nft/:id" element={<Detail/>} />
          <Route path="/assets/:address/:tokenId" element={<Detail/>} />
          <Route path="/collect/:id" element={<Collect />} />
          <Route path="/collect-result" element={<CollectResult />} />
          <Route path="/event" element={<Events />} />
          <Route path="/event/:id" element={<EventDetail />} />
          <Route path="/collection/:id" element={<CollectionList />} />
          <Route path="/user/:id" element={<User/>}/>
          <Route path="/authentication" element={<Authentication/>}/>
          <Route path="/settings" element={<Settings/>}/>
          <Route path="/" element={<CommonHomePage/>}/>
          <Route path="/homepage" element={isMobile?<Home/>:<HomeDesktop/>} />
          <Route path="/commonDetails/:id" element={<CommonDetails/>}/>
          <Route path="/payment/:id" element={<PaymentOrderPage />}/>
          <Route path="/cardManage" element={<BankCardManagePage/>}/>
          <Route path="/newCard" element = {<AddNewCardPage/>}/>
          <Route path="/paymentOrderList" element={<PaymentOrderListPage/>}/>
          <Route path='/seriesDetails/:id' element={<SeriesDetails/>}/>
          <Route path="/library" element={<LibraryPage/>}/>
          <Route path="/series/:id" element={<SeriesPage/>} />
          <Route path="/ar" element={<ArPage/>} />
        </Routes>
        </ErrorBoundary>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
