import {AuthContext, serverAddress} from "../App";
import {useContext} from "react";
import {bottomToast} from "./functionUtils";

export default function useImport() {
  const { state } = useContext(AuthContext);
  const post = async(address) => {
    let headers = new Headers();
    headers.append( "accessToken", state.accessToken );
    try {
      const response = await fetch(
        serverAddress + "/nfts/bindAddress",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ address: address, chain: "hyperchain" }),
        }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return "success";
      } else {
        bottomToast(json.msg);
      }
    } catch (error) {
      console.log("error", error);
      return "fail";
    }
  }

  return post;
}