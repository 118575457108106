import React, {useEffect, useRef, useState} from 'react';
import {Dialog} from '@mui/material';
import './nftShareDialog.css';
import useNftPoster from "../../../utils/useNftPoster";
import qrCode from '../assets/qrCode.png';
import logo from '../assets/logo.svg';
import * as htmlToImage from "html-to-image";
import download from "../../../assets/user/download.png";
import {Toast} from "antd-mobile";
import {smallAndProgressiveImage} from "../../../config";

export default function NftShareDialog(props) {
    const { setShareDialog, shareDialog, nft} = props;
    const [loadedCount, setLoadedCount] = useState(0);
    const getNftPoster = useNftPoster();
    const stageRef = useRef();

    const imageLoaded = ()=>{
        setLoadedCount(loadedCount+1);
    }

    const displayPoster = (url) => {
        const parentDiv = document.getElementById('stageDiv');
        if(parentDiv){
            parentDiv.innerHTML ='';
        }
        const result = document.createElement("img");
        result.style.width = '100%';
        result.src = url;
        stageRef.current.appendChild(result);
        result.onload = () =>{
            const mask = document.getElementById('unloaded');
            mask.remove();
        }
    }

    const getInfo=(index,nft)=>{
        if(index === 0){
            return nft?.collectionInfo?.name;
        }else if (index === 1){
            return nft?.ownedAt?.slice(0,10);
        }else if(index === 2){
            return nft?.collectionInfo?.publisher;
        }else{
            return nft?.creator;
        }
    }

    useEffect(()=>{
        if(!shareDialog){
            setLoadedCount(0);
        }else{
            if(loadedCount === 3){
                htmlToImage.toBlob(stageRef.current).then(() => {
                htmlToImage.toBlob(stageRef.current).then(() => {
                    htmlToImage.toBlob(stageRef.current).then(() => {
                        htmlToImage.toBlob(stageRef.current).then((blob) => {
                            let img = new File([blob],'poster.png');
                            let formData = new FormData();
                            formData.append('file', img);
                            getNftPoster(nft?.id,formData).then((res)=>{
                                displayPoster(res);
                            })});
                    });
                });
                });
            }
        }
    },[loadedCount, shareDialog])

    return (
        <Dialog open={shareDialog} onClose={()=> {setShareDialog(false)}}
                sx={{
                    "& .MuiDialog-container": {
                        backdropFilter: 'blur(2px)',
                    }}}
                PaperProps={{style: { borderRadius: 0}}}>
            <div style={{background: 'white', position: 'absolute', zIndex: '2', width: '100%', height: '100%'}} id={'unloaded'}/>
            <img src={download} alt={''}
                 style={{height: '24px', width: '24px', right: '15px', top: '12px', zIndex: '1', position:'absolute'}}
                 onClick={()=> {
                     Toast.show({
                         getContainer: document.getElementById('stageDiv'),
                         content: '长按图片下载',
                         position: 'center'
                     })
                 }}
            />
            <div className={'dialogContainer'} ref={stageRef} id={'stageDiv'}>
                <div className={'picContainer'}>
                    <img src={smallAndProgressiveImage(nft?.thumbnailURL)} alt={''} onLoad={imageLoaded} style={{objectFit: 'cover', width:'100%', height:'100%'}}/>
                </div>
                {
                    headersList.map((header,index)=>{
                        return (
                            <div className={'rowContainer'} key={index}>
                                <div className={'leftContainer'}>
                                    {header}
                                </div>
                                <div className={'rightContainer'}>
                                    {getInfo(index,nft)}
                                </div>
                            </div>
                        )
                    })
                }
                <div className={'bottomContainer'}>
                    <img src={qrCode} alt={''} onLoad={imageLoaded} style={{width:'61px',height: '61px'}}/>
                    <div className={'textContainer'}>
                        <div>扫描二维码</div>
                        <div>了解更多信息</div>
                    </div>
                    <img src={logo} alt={''} className={'logoContainer'} onLoad={imageLoaded}/>
                </div>
            </div>

        </Dialog>
    )
}

const headersList = [
    '藏品名','创建时间','发行方','创作者'
]

