import {AuthContext} from "../../App";
import {useNavigate} from "react-router";
import useProfile from "../../utils/useProfile";
import useLibrary from "../../utils/useLibrary";
import SideBar from "../../components/SideBar";
import LibraryIcon from './assets/libraryIcon.svg';
import HomeIcon from './assets/homeIcon.svg';
import WalletIcon from './assets/walletIcon.svg';
import playIcon from './assets/play.png';
import menuButton from "../../assets/menu.svg";
import galaxyLogo from "../../assets/galaxy-logo.svg";
import {useContext, useEffect, useRef, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./index.css";
import {smallAndProgressiveImage} from "../../config";

export default function LibraryPage() {
  const [profile, setProfile] = useState([]);
  const [Library, setLibrary] = useState([])
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalNum, setTotalNum] = useState([]);
  const { state } = useContext(AuthContext);
  const getProfile = useProfile();
  const sideBarRef = useRef();
  const navigate = useNavigate();
  const getLibrary = useLibrary();

  const fetchData = async() => {
    if (state.isLogin) {
      const profile = await getProfile(state.userId);
      localStorage.setItem('is_nft',profile?.is_nft);
      setProfile(profile);
      const data = await getLibrary(7, pageNum);
      setLibrary(Library.concat(data.galleries));
      setTotalNum(data.totalNum);
      if (pageNum > Math.floor(100/7)) {
        setHasMore(false);
      } else {
        setPageNum(pageNum + 1);
      }
    }
    if (state.isLogin === false) {
      navigate("/login");
    }
  }

  useEffect(() => {
    fetchData();
  },[state.isLogin])

  return (
    <div className="library-page">
      <div className="menu-bar">
        <img className="menu-button" src={menuButton} onClick={() => {sideBarRef.current.openSideBar()}} alt="" />
        <div className="flex flex-row">
          <img className="galaxy-logo" src={galaxyLogo} alt="" />
        </div>
      </div>
      <div className="main">
        <InfiniteScroll dataLength={Library.length} next={fetchData} hasMore={hasMore}
          loader={
            <p style={{ textAlign: 'center' }}>
              <b/>
            </p>
          }
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b/>
            </p>
          }
        >
          <div className="card-list">
            {Library.map((item, index) =>
              <LibraryCard id={item?.galleryId} name={item?.galleryName} image={item?.galleryImage} key={index} />
            )}
          </div>
        </InfiniteScroll>
      </div>
      <SideBar profile={profile} totalNum={totalNum} ref={sideBarRef}/>
      <div className="footerContainer">
        <div className='footerImg'>
          <div>
            <img src = {HomeIcon} alt='' className='leftImg' onClick={()=>{navigate('/')}}/>
          </div>
          <div>
            <img src = {LibraryIcon} alt='' className='middleImg' onClick={()=>{navigate('/library')}}/>
          </div>
          <div>
            <img src = {WalletIcon} alt='' className='rightImg' onClick={()=>{navigate('/homepage')}}/>
          </div>
        </div>
      </div>
    </div>
  )
}

function LibraryCard(params) {
  const navigate = useNavigate();
  return (
      <div className="library-card"
           onClick={() => {
             navigate("/series/" + params.id)
           }}
           style={{background: `url(${smallAndProgressiveImage(params.image)})`, backgroundSize: "cover", backgroundPosition: 'center'}}
      >
        <div className="mask"/>
        <div className="library-name">{params.name}</div>
        <div className="library-button">
          <img src={playIcon} alt="button-play"/>
          <div className="button-text">点击查看</div>
        </div>
      </div>
  )
}