import {AuthContext, serverAddress} from "../App";
import {useContext} from "react";

export default function useCollectResult() {
  const { state } = useContext(AuthContext);
  const collectResult = async(id) => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        serverAddress + "/nfts/txresult?requestId="+id,
        { method: "GET", headers: headers }
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }
  return collectResult;
}