import * as React from 'react';
import {isMobile} from 'react-device-detect';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import useLogout from "../utils/useLogout";
import useBindWallet from "../utils/useBindWallet";
import {useStatus} from '@cfxjs/use-wallet';

import "./Header.css";

import websiteLogo from "../assets/desktop/website-logo.svg";
import avatar from "../assets/desktop/avatar2900.png";
import iconBusinessCard from "../assets/desktop/businesscard-cion.svg";
import iconAbout from "../assets/desktop/about-icon.svg";
import iconAgreement from "../assets/desktop/agreement-icon.svg";
import iconLogout from "../assets/desktop/logout-icon.svg";
import helpOutline from "../assets/desktop/help-outline.svg";
import {Box} from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Box
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 40,
            top: 40,
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#2A7DF9",
          }}
        >
          了解数字钱包
        </Box>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

if (!isMobile) {
  var provider = window.conflux;
}

export default function Header() {  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
    connect();
  };
  const handleModalClose = () => setModalOpen(false);
  const logout = useLogout();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logout();
  }

  const status = useStatus();
  const [address, setAddress] = React.useState();
  const authed = (address) => {
    setAddress(address)
  }
  const connect = () => {
    provider.request({
      method: "cfx_requestAccounts",
    }).then(authed)
    .catch(error => console.error('error', error.message || error))
  }
  const sendSignature = useBindWallet();
  const bindWallet = () => {
    provider
    .request({
      method: 'personal_sign',
      params: [
        'bind address for cocafe',
        address[0],
      ],
    })
    .then(result => {
      sendSignature(address[0], result).then(res => {
        if (res) {
          setSnackbarOpen(true);
        }
      });
    })
    .catch(console.log)
  }

  return (
    <div className="header-desktop">
      <img className="website-logo" src={websiteLogo} alt="websiteLogo"/>
      <div className="menu">
        <IconButton
          id='avatar-button'
          aria-controls={isMenuOpen ? 'avatar-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <img className="avatar" src={avatar} alt="avatar"/>
        </IconButton>
        <button className='connect-wallet' onClick={handleModalOpen}>Wallet</button>
      </div>

      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'avatar-button',
        }}
        PaperProps={{ sx:{ width: 176, maxWidth: '100%'} }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -31,
          horizontal: 61
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <img src={iconBusinessCard} alt=""/>
          </ListItemIcon>
          <ListItemText>个人信息</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <img src={iconAbout} alt=""/>
          </ListItemIcon>
          <ListItemText>关于卡包</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <img src={iconAgreement} alt=""/>
          </ListItemIcon>
          <ListItemText>用户协议</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <img src={iconLogout} alt=""/>
          </ListItemIcon>
          <ListItemText>登出</ListItemText>
        </MenuItem>
      </Menu>
      
      <BootstrapDialog onClose={handleModalClose} className="wallet-dialog" aria-labelledby="customized-dialog-title" open={isModalOpen}
        sx={{ borderRadius: "12px",
          "& .MuiDialog-paper": { width: "585px", borderRadius: "12px" },
          "& .MuiDialogContent-root": { padding: "32px 36px" },
          "& .MuiDialogTitle-root": { padding: "28px 36px" }
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleModalClose}>链接我的数字钱包</BootstrapDialogTitle>
        <DialogContent dividers>
          <div className='wallet-link'>
            <a className='link' href="https://fluentwallet.com/" target="_blank" rel='noreferrer'>
              { status === 'not-active' ? "安装 Fluent Wallet" : "已安装 Fluent Wallet" }
            </a>
            <img src={helpOutline} alt="帮助" />
          </div>
          <div className='address-label'>默认查询地址：</div>
          <div className='address-value'>
            {address}
            <div className='address-notice'>星际岛将通过你钱包的默认地址：查询展示你在该钱包中所拥有的「数字藏品」</div>
          </div>
          <div className='wallet-action'>
            <button className='bind' onClick={bindWallet}>绑定钱包地址</button>
            <button className='close' onClick={handleModalClose}>关闭</button>
          </div>
        </DialogContent>
      </BootstrapDialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isSnackbarOpen}
        onClose={()=>{setSnackbarOpen(false)}}
        autoHideDuration={2000}
        message="绑定成功"
      />
    </div>
  )
}