import './Modal.css';
import CloseIcon from '../../../../assets/closeIcon.png';
import QrCode from '../../../../assets/qrCode.png';
import {useEffect} from "react";
import {stopBodyScroll} from "../../../../../../utils/functionUtils";

export default function Modal(props){
    const { visible, setDialog } = props;

    useEffect(()=>{
        stopBodyScroll(visible);
    },[visible])

    return(
        <div className='helpModalBackground' style={{ display: visible?'flex':'none'}}>
            <div className='modalContainer'>
                <div className='mainModal'>
                    <div className='modalTitle'>联系我们</div>
                    <div className='modalContent'>
                        <div className='qrCodeContainer'>
                            <div className='qrCodeBox'><img src ={QrCode} style={{height: '110px'}} alt=""/></div>
                        </div>
                        <div className='contentText'>
                            <div>扫一扫进入“咖菲科技”公众号</div>
                            <div>反馈相关问题</div>
                        </div>
                    </div>
                </div>
                <div className='modalLine'>
                    <img src={CloseIcon} className='closeIcon' onClick={()=>{setDialog(false)}} alt=""/>
                </div>
            </div>
        </div>
    )
}