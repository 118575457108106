import './collectionList.css';
import {memo, useEffect, useState} from "react";
import useNFTList from "../../../../utils/useNFTList";
import InfiniteScroll from "react-infinite-scroll-component";
import BackIcon from '../../../../assets/back.svg';
import {smallAndProgressiveImage} from "../../../../config";

export default function CollectionList(props){
    const{setDialog, visible, getImage, setSelectItem, selectItem} = props;
    const [pageNum, setPageNum] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [NFTList, setNFTList] = useState([]);

    const getNFTList = useNFTList();

    const fetchData = async() => {
        const data = await getNFTList(localStorage.getItem('userId'), 9, pageNum);
        setNFTList(NFTList.concat(data.nfts));
        if (pageNum > Math.floor(data.totalNum/9)) {
            setHasMore(false);
        } else {
            setPageNum(pageNum + 1);
        }
    }

    const cropper =()=>{
        getImage(selectItem.thumbnailURL+"!small");
        setDialog(false);
    }

    useEffect(()=>{
        fetchData();
    },[])

    return(
        <div className='collectionListContainer' style={{ display: visible?'flex':'none'}}>
            <img src={BackIcon} className='collectionCardBackIcon' onClick={()=>{setDialog(false)}} alt=""/>
            <div className='collectionCardTitle'>选择藏品</div>
            <InfiniteScroll dataLength={NFTList.length} next={fetchData} hasMore={hasMore}
                            loader={<p style={{ textAlign: 'center',color:'white' }}><b>加载中...</b></p>}>
            <div className='nftListContainer'>
                {NFTList.map((item,index)=>{
                    return(
                        <NftCard nft={item} key={index} setSelectItem={setSelectItem} selectItem={selectItem}/>
                    )
                })}
            </div>
            </InfiniteScroll>
            {selectItem? <div className='footerContainer'>
                <div className='collectionCardButton' onClick={cropper}>
                    下一步
                </div>
            </div>:null }

        </div>
    )
}

const NftCard = memo((props) => {
    const {nft, setSelectItem, selectItem} = props;

    const clickItem = ()=>{
        if(selectItem === nft){
            setSelectItem(undefined);
        }else{
            setSelectItem(nft);
        }
    }

    return (
        <div className='collectionCardContainer' onClick={clickItem}
             style={{opacity: selectItem === undefined ? '1' : selectItem === nft ? '1' : '0.5'}}>
            <div className='collectionImgContainer'>
                <img src={smallAndProgressiveImage(nft?.thumbnailURL)} alt=''/>
            </div>
            <div className='collectionItemTitle'>{nft?.name}</div>
        </div>
    )
})
