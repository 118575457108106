import {useContext} from "react";
import {AuthContext, portalAddress} from "../App";
import {Toast} from "antd-mobile";

export default function useLogout() {
  const { state, dispatch } = useContext(AuthContext);

  const logout = async() => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        portalAddress + "/user/logout", 
        {
          method: "POST",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        dispatch({
          type: "LOGOUT",
        });
        window.collectEvent('config', {user_unique_id: null});
        return true;
      } else {
        Toast.show({
          content:json.msg,
          position:"bottom"
        })
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return logout;
}