import {AuthContext, serverAddress} from "../App";
import {Toast} from "antd-mobile";
import {useContext} from "react";

export default function useOrderList() {
    const { state } = useContext(AuthContext);
    let headers = new Headers();
    headers.append( "accessToken", state.accessToken);

    const orderList = async(pageSize, pageNum) => {
        try {
            const response = await fetch(
                serverAddress + "/payment/listOrders?pageSize=" + pageSize + "&pageNum=" + pageNum + "&sortBy=&orderBy=",
                {
                    method: "GET", headers: headers,
                });

            const json = await response.json();

            if (json.returnCode === "20000") {
                return json.data;
            } else {
                Toast.show({
                    content:json.msg,
                    position:"bottom"
                })
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return false;
        }
    }

    return orderList;
}