import React, {useEffect, useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import {Dialog} from '@mui/material';
import './GideDialog.css';
import closeIcon from "../assets/close.png";
import cardImg from "../assets/card-image.png";

export default function GidePayDialog(props) {
  const { setGideDialog, gideDialog, makeCard, viewUrl} = props;
  const [checked, setChecked] = useState(false);
  const checkboxOnchanged = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setChecked(false)
  },[gideDialog])

  return (
    <Dialog className='gide-dialog' sx={{"& .MuiDialog-paper": { marginTop:"-35px", width: "343px", background: "#282C49", padding: "17px", borderRadius: "6px", overflow: 'visible'}}} open={gideDialog} >
      <div className='card-intro'>
        <img className='card-img' src={cardImg} alt=""/>
        <div className='question'>什么是实体卡片</div>
        <div className='answer'>全国首家展示数字藏品的实体卡，让您的数字藏品看得见，摸得着，Show得出，让您拥有独一无二的元宇宙和现实世界的融合体验</div>
      </div>
      {(() => {
        switch (props.status) {
          case "orderNotMade":
            return <div>
                    <div className='check-approval'>
                      <Checkbox sx={{color: "rgba(255, 255, 255, 0.5)"}} onChange={checkboxOnchanged} size="small"/>
                      <div>授权制卡方获取您的用户信息</div>
                    </div>
                    <button className='to-generate' disabled={!checked} onClick={makeCard}>前往制作</button>
                  </div>
          case "orderDoing":
            return <div>
                    <div className='check-approval'>
                      <div>您的卡片正在制作中请耐心等待，<p style={{ display: "inline", color: "red"}}><a href={viewUrl} style={{textDecoration: "underline", color: "red"}}>查看详情</a></p></div>
                    </div>
                    <button className='to-generate' disabled={true}>制作中</button>
                  </div>
          case "orderFailed":
            return <div>
                    <div className='check-approval'>
                      <div><p style={{ display: "inline"}}><a href={viewUrl} style={{textDecoration: "underline", color: "red"}}>您的卡片制作失败，查看详情</a></p></div>
                    </div>
                    <button className='to-generate' onClick={makeCard}>重新制作</button>
                  </div>
        
          default:
            return null
        }
      })()}
      <div className="dialog-close">
        <div className="close-line"></div>
        <img className="close-icon" src={closeIcon} onClick={() => {setGideDialog(false)}} alt="" />
      </div>
    </Dialog>
  )
}
