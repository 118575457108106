import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

let sentryEnv = "dev";
if (process.env.REACT_APP_SERVER_ENV) {
  sentryEnv = process.env.REACT_APP_SERVER_ENV;
}

Sentry.init({
  dsn: "https://050734c1431c4eaa8113223c5bf0cb53@szs-t2.s.cocafe.co/3",
  integrations: [new BrowserTracing()],
  environment: sentryEnv,
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
