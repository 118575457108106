import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import useLogout from "../utils/useLogout";
import Agreement from "../components/Agreement";
import {useNavigate} from 'react-router-dom';
import "./SideBar.css";

import walletIcon from "../assets/wallet-icon.svg";
import pictureIcon from "../assets/picture-icon.svg";
import authIcon from "../assets/auth-icon.svg";
import personalIcon from "../assets/nicknameLogo.svg";
import HexagonAvatar from "./hexagonAvatar/HexagonAvatar";
import {smallAndProgressiveImage} from "../config";

function SideBar({profile, totalNum}, ref) {

  const [open, setOpen] = useState(false);
  const agreementRef = useRef();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    openSideBar() {
      setOpen(true);
    }
  }), [])

  const toggleDrawer = (open) => (event) => {
    if ( event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift') ) return;
    setOpen(open);
  };

  const logout = useLogout();
  const handleLogout = () => {
    logout();
  }

  const toSetting = () =>{
    navigate('/settings');
  }

  return (
    <SwipeableDrawer sx={{"& .MuiPaper-root": {color: "#FFFFFF", backgroundColor: "#0F172A"}}} className='sidebar' anchor="left" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
      <div className="profile">
        {/*<img className="profile-avatar" src={profile?.avatarURL} alt=''/>*/}
        <HexagonAvatar hexagon={profile?.is_nft === 1} scale={0.8} src={smallAndProgressiveImage(profile?.avatarURL)}/>
        <div className="profile-username">{profile.userName}</div>
        {/* <div className="profile-introduction">{profile.introduction === "" ? "这个人很懒，什么都没有留下" : profile.introduction}</div> */}
      </div>
      <div className='content'>
        <div className='title-container'>
          <img className='wallet-icon' src={walletIcon} alt=""/>
          <div className='title'>咖菲卡包</div>
          <div className='nft-num'>已拥有{totalNum}个NFT</div>
        </div>
        <ul className='menu-list'>
          <li onClick={()=>{toSetting()}}><img src={personalIcon} alt=""/>个人中心</li>
        </ul>
        <ul className='menu-list'>
          <li onClick={()=>{agreementRef.current.openAgreement()}}><img src={pictureIcon} alt=""/>用户协议</li>
        </ul>
        <ul className='menu-list'>
          <li onClick={()=>{navigate("/authentication")}}><img src={authIcon} alt=""/>实名认证</li>
        </ul>
        <div className='logout-container' onClick={handleLogout}>
          <button className='logout'>登出</button>
        </div>
      </div>
      <Agreement ref={agreementRef}/>
    </SwipeableDrawer>
  )
}

export default forwardRef(SideBar)