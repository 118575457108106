import {AuthContext} from "../../App";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Toast} from "antd-mobile";
import "./Authentication.css";
import useSMS from "../../utils/useSMS";
import {useGetAuthentication, usePostAuthentication} from "../../utils/useAuthentication";
import backImage from "../../assets/back.svg";
import linerColorText from "./assets/code.svg";
import {validateCode} from "../../utils/validator";

export default function Authentication() {

  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [idCard, setIdCard] = useState('');
  const [code, setCode] = useState('');
  const [codeBtnDisabled, setCodeBtnDisabled] = useState(false);
  const [count, setCount] = useState(0);
  const [codeBtnText, setCodeBtnText] = useState("");
  const [buttonTextDisplay, setButtonTextDisplay] = useState(true);
  const sms = useSMS(state.phone);
  const [authInformation, setAuthInformation] = useState();
  const [authenticated, setAuthenticated] = useState();
  const [codeSent, setCodeSent] = useState(false);
  const postAuth = usePostAuthentication();
  const getAuth = useGetAuthentication();

  const bottomToast = (content) => {
    Toast.show({
      content: content,
      position: 'bottom',
    })
  }

  const nameOnchanged = (e) => {
    setName(e.target.value);
  };

  const idOnchanged = (e) => {
    setIdCard(e.target.value);
  };

  const codeOnchanged = (e) => {
    setCode(e.target.value);
  };

  const getCode = async() => {
    sms(state.phone).then((res) => {
      if (res) {
        bottomToast("验证码已发送");
        setCodeSent(true);
        setCodeBtnDisabled(true);
        setCount(60);
      } else {
        bottomToast("验证码发送失败");
        setCodeBtnText("");
        setButtonTextDisplay(true);
        setCount(0);
        setCodeBtnDisabled(false);
      }
    });
  };

  const submitAuth = async() => {
    if (codeSent && validateCode(code) && name) {
      const res = await postAuth(code, name, idCard);
      if (res && res.authenticated) {
        bottomToast("认证成功！")
        setAuthenticated(1);
      } else {
        setCodeSent(false);
      }
    } else if (!codeSent) {
      bottomToast("请先获取验证码");
    } else if (!name) {
      bottomToast("请输入姓名");
    } else if (!idCard) {
      bottomToast("请输入身份证号");
    } else if (!code) {
      bottomToast("请输入验证码");
    } else if (!validateCode(code)) {
      bottomToast("验证码错误");
    } 
  }

  useEffect(() => {
    if (count > 1) {
      setButtonTextDisplay(false);
      setCodeBtnText(count +"s");
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
    if (count === 1) {
      setButtonTextDisplay(false);
      setCodeBtnText(count +"s");
      setTimeout(() => {
        setCodeBtnText("");
        setButtonTextDisplay(true);
        setCount(0);
        setCodeBtnDisabled(false);
      }, 1000);
    }
  }, [count]);

  useEffect(() => {
    const getAuthInformation = async() => {
      const info = await getAuth();
      setAuthInformation(info);
      setAuthenticated(info.authenticated);
    }
    if (state.accessToken) {
      getAuthInformation();
    }
  },[state.accessToken])

  switch (authenticated) {
    case 1:
      return (
        <div className="authentication">
        <img src={backImage} onClick={()=>{navigate(-1)}} alt=""/>
          <div className="authed">
            <img className="avatar" src={state.avatarURL} alt=""/>
            <div className="name">{authInformation?.userName}</div>
            <div className="id-card">{authInformation?.IDCard && (authInformation.IDCard?.substr(0, 3) + " **** **** **** " + authInformation.IDCard?.substr(18-4,18))}</div>
            <div className="phone-container">
              <div className="phone">{authInformation?.phone && (authInformation.phone?.substr(0, 3) + "****" + authInformation.phone?.substr(11-4,11))}</div>
              <div className="current">当前账号</div>
            </div>
          </div>
        </div>
      )
  
    case 0:
      return (
        <div className="authentication">
          <img className="go-back" src={backImage} onClick={()=>{navigate(-1)}} alt=""/>
          <div className="title">实名认证</div>
          <div className="subtitle">以下信息用于核实您的真实身份</div>
          <div className="input-label">真实姓名</div>
          <input className="auth-input" placeholder="请填写您本人的真实姓名" onChange={nameOnchanged} />
          <div className="input-label">身份证号</div>
          <input className="auth-input" placeholder="请填写您本人的身份证号" onChange={idOnchanged}/>
          <div className="input-label">手机号 {state.phone && (state.phone?.substr(0, 3) + "****" + state.phone?.substr(11-4,11))}</div>
          <div className="code-area">
            <input className="auth-input" placeholder="输入验证码" onChange={codeOnchanged} />
            <button onClick={getCode} disabled={codeBtnDisabled} className="code">
              {codeBtnText}
              {buttonTextDisplay ? <img src={linerColorText} alt="code" /> : null}
            </button>
          </div>
          <div className="notice">应法律要求，所有使用数字服务的用户都需要提交实名信息</div>
          <button className="confirm-auth" onClick={submitAuth}>提交认证</button>
        </div>
      )
      
    default:
      return null
  }
}