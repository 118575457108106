import {serverAddress} from "../App";
import {Toast} from "antd-mobile";

export default function useHomepageCollectionList() {

    const homepageCollectionList = async(pageSize, pageNum,status,orderBy) => {
        // let headers = new Headers();
        // headers.append("accessToken", state.accessToken);

        try {
            const response = await fetch(
                serverAddress + "/payment/display?pageSize=" + pageSize + "&pageNum=" + pageNum + "&sortBy=" + status + "&orderBy=" + orderBy,
                { method: "GET" }
            );

            const json = await response.json();

            if (json.returnCode === "20000") {
                return json.data;
            } else {
                Toast.show({
                    content:json.msg,
                    position:"bottom"
                })
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return false;
        }
    }

    return homepageCollectionList;
}