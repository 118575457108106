import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetCardUrl, useNFTDetail, usePostCardUrl} from "../../utils/useNFTDetail";
import {Toast} from "antd-mobile";
import {AuthContext} from "../../App";
import {smallAndProgressiveImage} from "../../config";
import "./Detail.css";

import playIcon from '../seriesDetails/assets/playIcon.png';
import backButton from "../../assets/back.svg";
import arButton from './assets/ar-button.svg';
import shareButton from "./assets/share.svg";
import blackBiscuit from "../../assets/biscuit.png";
import whiteBiscuit from "../../assets/biscuit-white.png";
import chatButton from "../../assets/chat-button.svg";
import NftShareDialog from "./components/nftShareDialog";
import AiChartPage from '../aiChatPage/AiChatPage';
import GidePayDialog from "./components/GideDialog";

export default function Detail() {
  const { state } = useContext(AuthContext);
  const params = useParams();
  const contractAddress = params.address;
  const nftId = params.id;
  const tokenId = params.tokenId;
  const [nft, setNFT] = useState();
  const [viewUrl, setViewUrl] = useState();
  const getNFTDetail = useNFTDetail();
  const getNFTDetailByContract = useNFTDetail("contract");
  const getCardUrl = useGetCardUrl();
  const postCardUrl = usePostCardUrl();
  const [displayType,setDisplayType] = useState();
  const navigate = useNavigate();
  const [collectable, setCollectable] = useState(true);
  const [shareDialog, setShareDialog] = useState(false);
  const [aiDialog, setAiDialog] = useState(false);
  const fromMap = new Map([["gd", "/"]]);
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);

  const openAiDialog = ()=>{setAiDialog(true)};
  const [gideDialog, setGideDialog] = useState(false);
  const [btnText, setBtnText] = useState("生成实体卡");

  const getSubStr = (str) => {
    if (str) {
      var subStr1 = str.substr(0, 8);
      var subStr2 = str.substr(str.length - 4, 17);
      var subStr = subStr1 + "..." + subStr2;
      return subStr;
    }
  };

  const copyAddress = (address) => () => {
    navigator.clipboard.writeText(address).then(() => {
      Toast.show({
        icon: 'success',
        content: '已复制',
      })
    },() => {
      Toast.show({
        icon: 'success',
        content: '复制失败',
      })
    },);
  }

  const handleGide = () => {
    if (nft.cardStatus !== "orderSuccess") {
      setGideDialog(true)
    } else {
      window.location.href = viewUrl;
    }
  }

  const toArSeries = ()=>{
    navigate(`/series/${nft?.galleryInfo?.galleryId}`)
  }

  const arButtonClick = ()=>{
    navigate("/ar?url=" + nft?.ARDisplay)
  }

  const makeCard = async() => {
    const data = await postCardUrl(nft.id);
    if (data) {
      window.location.href = data.url;
    }
  }

  useEffect(() => {
    async function fetchData() {
      if (state.isLogin) {
        let nft;
        if (contractAddress) {
          nft = await getNFTDetailByContract(contractAddress, tokenId);
        } else {
          nft = await getNFTDetail(nftId);
        }
        setNFT(nft);
        if (nft.cardStatus && nft.cardStatus !== "orderNotMade") {
          const data = await getCardUrl(nft.id);
          setViewUrl(data.url);
        }
        if (nft.displayURL) {
          fetch(nft.displayURL)
          .then(
            response => {
              const type = response.headers.get("Content-Type");
              const regex = /.*(?=\/)/;
              setDisplayType(regex.exec(type)[0]);
            }
          )
        }
        if (nft.collectStatus==="nftCollected") {
          setCollectable(false);
        }
      }
    }
    fetchData();
  }, [state.isLogin])

  useEffect(() => {
    switch (nft?.cardStatus) {
      case "orderDoing":
          setBtnText("制作中");
        break;
      case "orderSuccess":
          setBtnText("查看实体卡");
        break;
      case "orderFailed":
          setBtnText("制作失败");
        break;
      default:
        break;
    }
  }, [nft])

  return (
    <div className="detail-page">

      <div className="header">
        <img className="back" src={backButton} alt="back" onClick={()=>{
          if (searchParams.get("from") === "gd") {
            const route = fromMap.get(searchParams.get("from"));
            console.log(route);
            navigate(route);
          } else {
            navigate(-1);
          }
        }}/>
        <div className="name">{nft?.name}</div>
        <img className="share" src={shareButton} alt="share" onClick={()=>{setShareDialog(true)}}/> 
      </div>

      <div className="id">#{nft?.tokenId}</div>
        {nft?.collectionId === 5 ?
          <div className="nfo-container">
            <img className="nfo-image" src={smallAndProgressiveImage(nft?.displayURL)} alt="" />
            {nft?.type === "white" ?
              <img className="biscuit" src={whiteBiscuit} alt="" />:
              <img className="biscuit" src={blackBiscuit} alt="" />
            }
            {nft?.bot === 1 ?<div className="ai-nft">AI NFT</div>:null}
            {nft?.bot === 1 && nft.ownerUserInfo?.userId === state.userId ?
            <div className="chat-button" onClick={openAiDialog}>
              <img src={chatButton} alt="" />
              <div className="button-name">聊一聊</div>
            </div>:null}
          </div>:
          <div className="nft-container">
            {(() => {
              switch (displayType){
                case "image":
                  return <img className="nft-image" src={smallAndProgressiveImage(nft?.displayURL)} alt=""/>
                case "video":
                  return (<video className="nft-image" poster={smallAndProgressiveImage(nft?.thumbnailURL)} autoPlay loop muted playsInline controls>
                            <source src={nft?.displayURL} />
                          </video>)
                default:
                  return null
              }
            })()}
            {nft?.bot === 1?<div className="ai-nft">AI NFT</div>:null}
            {nft?.bot === 1 && nft.ownerUserInfo.userId === state.userId ?
              <div className="button-container">
                <div className="chat-button" onClick={openAiDialog}>
                  <img src={chatButton} alt=""/>
                  <div className="button-name">聊一聊</div>
                </div>
              </div>:null}
            {nft?.galleryInfo?
              <div className="button-container">
                <div className="chat-button" onClick={arButtonClick}>
                  <img src={arButton} alt=""/>
                  <div className="button-name">AR</div>
                </div>
              </div>:null}
          </div>
        }
      {nft?.galleryInfo ?
          <div className='ar-container'
               style={{backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, 0), rgba(40, 44, 73, 1) 75%), url(${nft?.galleryInfo?.smallGalleryImage})`}}>
            <div className='gallery-content-container'>
              <div className='gallery-name'>
                {nft?.galleryInfo?.galleryName}
              </div>
              <div className='inline-button-container'>
                <img src={playIcon} alt=""/>
                <div className='button-content' onClick={toArSeries}>点击查看</div>
              </div>
            </div>
          </div>
          : null}
      <div className="collector-card">
        <div className="card-title">藏家信息</div>
        <div className="divider"/>
        <div className="card-content">
          <div className="profile-container">
            <img className="avatar" src={nft?.ownerUserInfo.avatarURL} alt=""/>
            <div className="username">{nft?.ownerUserInfo.userName}</div>
          </div>
            <button className="check-info">
              <div className="btn-text" onClick={() => {navigate("/user/" + nft.ownerUserInfo.userId)}}>查看</div>
            </button>
        </div>
      </div>

      <div className="information-card">
        <div className="card-title">藏品信息</div>
        <div className="divider"/>
        <table className="information-content">
          <tbody>
            <tr>
              <td className="table-label">项目名称</td>
              <td className="table-value">{nft?.collectionInfo.name}</td>
            </tr>
            <tr>
              <td className="table-label">拥有者地址</td>
              <td className="table-value address" onClick={copyAddress(nft?.ownerUserInfo.address)}>{getSubStr(nft?.ownerUserInfo.address)}</td>
            </tr>
            <tr>
              <td className="table-label">发行方</td>
              <td className="table-value">{nft?.collectionInfo.publisher}</td>
            </tr>
            <tr>
              <td className="table-label">合约地址</td>
              <td className="table-value address" onClick={copyAddress(nft?.collectionInfo.contractAddress)}>{getSubStr(nft?.collectionInfo.contractAddress)}</td>
            </tr>
            <tr>
              <td className="table-label">描述</td>
              <td className="table-value description">{(nft?.description)===""?"-":nft?.description}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {
        (nft?.ownerUserInfo.userId && state?.userId && nft?.ownerUserInfo.userId === state?.userId)&&
        <button className="claim" disabled={!collectable} onClick={handleGide}>{btnText}</button>
      }
      
      <NftShareDialog setShareDialog={setShareDialog} shareDialog={shareDialog} nft={nft}/>
      <AiChartPage setAiDialog={setAiDialog} aiDialog={aiDialog} nftId={nft?.id} nft={nft}/>
      <GidePayDialog setGideDialog={setGideDialog} gideDialog={gideDialog} makeCard={makeCard} viewUrl={viewUrl} status={nft?.cardStatus}/>
    </div>
  )
}