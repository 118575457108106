import {useContext} from "react";
import {AuthContext, serverAddress} from "../App";
import {Toast} from "antd-mobile";

export default function useCollectSMS() {
  const { state } = useContext(AuthContext);
  const collectSMS = async (nftId) => {
    let headers = new Headers();
    headers.append("accessToken", state.accessToken);
    try {
      const response = await fetch(
        serverAddress + "/nfts/collect/sendSms", 
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({nftId: nftId }),
        }
      );
      const json = await response.json();
      if (json.returnCode === "20000") {
        return json.data.requestId;
      } else {
        Toast.show({
          content:json.msg,
          position:"bottom"
        })
        return false;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  return collectSMS;
}