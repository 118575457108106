import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {Toast} from "antd-mobile";
import Checkbox from '@mui/material/Checkbox';
import useLogin from "../utils/useLogin";
import useSMS from "../utils/useSMS";
import Agreement from "../components/Agreement";
import {validateCode, validatePhone} from "../utils/validator";
import "./Login.css";

import logo from "../assets/logo.png";
import loginPoster from "../assets/login-background.png";

export default function Login() {
    const [phone, setPhone] = useState();
    const [code, setCode] = useState();

    const [agreementAlert, setAgreementAlert] = useState(false);

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("获取验证码");
    const [count, setCount] = useState(0);
    const agreementRef = useRef();

    const phoneOnchanged = (e) => {
        setPhone(e.target.value);
    };

    const codeOnchanged = (e) => {
        setCode(e.target.value);
    };

    const bottomToast = (content) => {
        Toast.show({
            content: content,
            position: 'bottom',
        })
    }

    const loginAlert = {
        codeSuccess: "获取验证码成功",
        loginSuccess: "登录成功",
        emptyPhone: "请输入你的手机号",
        emptyCode: "请输入你的验证码",
        invalidPhone: "请输入正确的手机号",
        invalidCode: "请输入正确的验证码",
    }

    const sms = useSMS(phone);
    const getSMS = async () => {
        if (validatePhone(phone)) {
            sms().then((res) => {
                if (res) {
                    bottomToast(loginAlert.codeSuccess);
                    setBtnDisabled(true);
                    setCount(60);
                } else {
                    bottomToast(loginAlert.invalidPhone);
                    setBtnText("获取验证码");
                    setCount(0);
                    setBtnDisabled(false);
                }
            });
        } else if (!phone) {
            bottomToast(loginAlert.emptyPhone);
        } else {
            bottomToast(loginAlert.invalidPhone);
        }
    };

    const login = useLogin();
    const navigate = useNavigate();
    const handleConfirm = () => {
        if (!phone) {
            bottomToast(loginAlert.emptyPhone);
        } else if (!code) {
            bottomToast(loginAlert.emptyCode);
        } else if (!validatePhone(phone)) {
            bottomToast(loginAlert.invalidPhone);
        } else if (!validateCode(code)) {
            bottomToast(loginAlert.invalidCode);
        } else if (checked === true) {
            login(phone, code).then((res) => {
                if (res === false) {
                    bottomToast(loginAlert.invalidCode);
                } else {
                    bottomToast(loginAlert.loginSuccess);
                    navigate("/");
                }
            })
        } else {
            setAgreementAlert(true);
            bottomToast("请仔细阅读并勾选用户协议");
        }
    };

    const [checked, setChecked] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        if (count > 1) {
            setBtnText(count + "s");
            const timeout = setTimeout(() => {
                setCount(count - 1);
            }, 1000);
            return () => clearTimeout(timeout);
        }
        if (count === 1) {
            setBtnText(count + "s");
            const timeout = setTimeout(() => {
                setBtnText("获取验证码");
                setCount(0);
                setBtnDisabled(false);
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [count]);

    return (
        <div className="login">

            <video playsInline autoPlay muted loop poster={loginPoster} id="bgvid">
                <source src="https://elements.cocafe.co/cowallet/static/login.mp4" type="video/mp4"/>
            </video>

            <div className="main">
                <div className="title-container">
                    <img className="title" src={logo} alt=""/>
                </div>

                <div className="input-container">
                    <div className="input-wrapper">
                        <input className="phone" type="tel" size="1" maxLength="11" onChange={phoneOnchanged}
                               placeholder="请输入手机号"/>
                    </div>
                </div>

                <div className="input-container">
                    <div className="input-wrapper">
                        <input className="code" type="tel" size="1" maxLength="6" onChange={codeOnchanged}
                               placeholder="请输入验证码"/>
                    </div>
                    <button onClick={getSMS} disabled={btnDisabled} className="code">
                        {btnText}
                    </button>
                </div>

                <div className="agreement-container">
                    <Checkbox onChange={handleChange} sx={{
                        padding: "0",
                        color: (agreementAlert && !checked) ? "red" : "#7C7C7C",
                        '& .MuiSvgIcon-root': {fontSize: "4.5333vw"}
                    }}/>
                    <div className="agreement-label">
                        <span style={{color: (agreementAlert && !checked) ? "red" : "#7C7C7C"}}>我已阅读并同意</span>
                        <div className="relative" style={{color: (agreementAlert && !checked) ? "red" : "#2A7DF9"}}
                             onClick={() => {
                                 agreementRef.current.openAgreement()
                             }}>《用户协议》
                        </div>
                    </div>
                </div>

                <div className="next-container">
                    <button className={`next ${checked ? "checked" : null}`} onClick={handleConfirm}>登录</button>
                </div>
            </div>
            <Agreement ref={agreementRef}/>
        </div>
    );
}