import './Card.css';
import OnSaleIcon from '../../assets/onSaleIcon.png';
import CountDown from "../../../../components/countDown/CountDown";
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import {bottomToast} from "../../../../utils/functionUtils";
import AuthDialog from "../../../homePage/components/AuthDialog";
import {useState} from "react";
import ProgressiveImage from "../../../../components/progressiveImage/ProgressiveImage";

export default function Card(props){
    const { nft } = props;
    const [authOpen, setAuthOpen] = useState(false);
    const navigate = useNavigate();

    const toDetailsPage = () =>{
        if(!localStorage.getItem('userId')){
            bottomToast('请先登录')
        } else if(localStorage.getItem('authenticated') === '1'){
            navigate(`commonDetails/${nft?.releaseId}`, {state: {nft: nft}});
        }else{
            setAuthOpen(true);
        }
    }

    return(
        <div className='cardContainer'>
            <AuthDialog authDialog={authOpen} setAuthDialog={setAuthOpen}/>
            <div className='card'>
                {/*<div className='img'>*/}
                {/*    <img src={smallAndProgressiveImage(nft?.releaseImage)} alt='' />*/}
                {/*</div>*/}
                <div className='img'>
                    <ProgressiveImage alt='' src={nft?.releaseImage}/>
                </div>
                <Tag nft={nft}/>
                <div className='cardAvatar'>
                    <ProgressiveImage alt='' src={nft?.releaseImage}/>
                </div>
            </div>
            <div className='name'>{nft?.publisher}</div>
            <div className='title'>{nft?.releaseName}</div>
            <div className='button' onClick={toDetailsPage}>立即查看</div>
        </div>
    )
}

const Tag = (props) =>{
    const { nft }  = props;

    const diffSeconds = dayjs(nft?.saleAt).diff(dayjs(),'seconds');

    switch (nft?.status) {
        case 'onSale':
            return(
                <div className='tagContainer'>
                    <img src = {OnSaleIcon} alt='' className='onSaleIcon'/>
                    发售中
                </div>
            )

        case 'endOfSale':
            return(
                <div className='tagContainer'>
                    已售罄
                </div>
            )

        case  'comingSoon':
        return(
            <div className='tagContainer'>
                <CountDown hours={`${diffSeconds/3600}`} minutes={`${(diffSeconds%3600)/60}`} seconds={`${diffSeconds%60}`} type={'sale'}/>
            </div>
            )

        default:
           return null;
    }
}